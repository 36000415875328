<template>
  <Step heading="Cancel Promise to Pay">
    <p class="">
      By canceling your Promise to Pay agreement, you may be subject to service disruption dependent
      on the status of your account and if any past due balance remains.
    </p>
    <template #footer>
      <BzlButton
        @click="$emit('approve')"
        data-ga-category="Promise to Pay"
        data-ga-action="trigger_confirm_cancel_of_promise_to_pay"
        data-ga-label="User clicked the submit to Cancel promise to pay button"
        class="track"
      >
        Confirm Cancellation
      </BzlButton>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'

  export default {
    name: 'PromiseToPayCancel',
    components: {
      Step,
    },
  }
</script>
