<template>
  <div class="fixed bottom-0 z-50 w-full bg-white lg:relative lg:h-full lg:w-auto">
    <div
      class="flex flex-row items-stretch justify-between lg:h-full lg:flex-col lg:justify-evenly"
    >
      <div
        class="hidden border-b border-gray p-6 lg:block"
        role="region"
      >
        <a href="/">
          <img
            src="/logo.svg"
            class="max-h-8 w-36 xl:max-h-10 xl:w-52"
            alt="Logo"
          />
        </a>
      </div>

      <button
        v-for="item in firstMenuItems"
        :key="item.routeName"
        type="button"
        class="relative flex w-full flex-col items-center py-6 text-sm text-navy-dark before:absolute before:top-0 before:h-full before:w-full before:border-r before:border-gray before:content-[''] hover:bg-gray-light lg:flex-row lg:text-base before:lg:border-b before:lg:border-r-0"
        :class="
          isCurrentRoute(item.routeName)
            ? `after:absolute after:top-0 after:w-full after:border-l-0 after:border-r after:border-t-8 after:border-blue after:content-[''] after:lg:h-full after:lg:w-auto after:lg:border-l-8 `
            : ''
        "
        @mousedown="gaEvent(item.label)"
        @click="$emit('route-to', item.routeName)"
      >
        <div class="flex flex-col items-center lg:ml-8 lg:flex-row">
          <span class="mb-2 flex items-center lg:mb-0 lg:mr-2 lg:w-8">
            <BzlIcon
              :custom-icon-name="item.label"
              :src="item.iconName"
              icon-color="#0057B8"
              style="font-size: 1.25rem"
            ></BzlIcon>
          </span>
          <span
            class="text-xs leading-none lg:text-base"
            :class="isCurrentRoute(item.routeName) ? 'lg:font-bold' : null"
          >
            {{ item.label }}
          </span>
        </div>
      </button>

      <div class="mt-auto w-full">
        <button
          v-for="item in lastMenuItems"
          :key="item.routeName"
          type="button"
          class="relative flex w-full flex-col items-center py-6 text-sm text-navy-dark before:absolute before:top-0 before:h-full before:w-full before:border-r before:border-gray before:content-[''] last:hidden hover:bg-gray-light lg:flex-row lg:text-base before:lg:border-r-0 before:lg:border-t last:lg:flex"
          :class="
            isCurrentRoute(item.routeName)
              ? `after:absolute after:top-0 after:w-full after:border-l-0 after:border-r after:border-t-8 after:border-blue after:content-[''] after:lg:h-full after:lg:w-auto after:lg:border-l-8`
              : ''
          "
          @click="$emit('route-to', item.routeName)"
        >
          <div class="flex flex-col items-center lg:ml-8 lg:flex-row">
            <span class="mb-2 lg:mb-0 lg:mr-2 lg:w-8">
              <BzlIcon
                :custom-icon-name="item.iconName"
                :src="item.iconName"
                icon-color="#0057B8"
                style="font-size: 1.25rem"
              ></BzlIcon>
            </span>
            <span
              class="text-xs leading-none lg:text-base"
              :class="isCurrentRoute(item.routeName) ? 'lg:font-bold' : null"
            >
              {{ item.label }}
            </span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import MyAccountQr from './my-account.png'
  import CircleOpenIcon from './circle-open.svg'
  import DocumentIcon from './document.svg'
  import CircleWrenchIcon from './circle-wrench.svg'
  import ModemIcon from './modem.svg'
  import UserIcon from './user.svg'
  import Lock from './lock.svg'
  import QuestionMark from './question-mark.svg'

  export default {
    name: 'AppMenu',

    props: {
      currentRouteName: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      menuItems: [
        {
          label: 'Dashboard',
          iconName: CircleOpenIcon,
          routeName: 'dashboard',
        },
        {
          label: 'Billing',
          iconName: DocumentIcon,
          routeName: 'billing',
        },
        {
          label: 'Equipment',
          iconName: ModemIcon,
          routeName: 'equipment',
        },
        {
          label: 'Support',
          iconName: QuestionMark,
          routeName: 'support',
        },
        {
          label: 'Account',
          iconName: UserIcon,
          routeName: 'account',
        },
        {
          label: 'Sign Out',
          iconName: Lock,
          routeName: 'log-out',
        },
      ],
      MyAccountQr,
    }),
    computed: {
      firstMenuItems() {
        return this.menuItems.slice(0, 4)
      },
      lastMenuItems() {
        return this.menuItems.slice(-2)
      },
    },
    methods: {
      gaEvent(label) {
        if (label.includes('Billing')) {
          this.trackEvent('trigger_billing_clicked')
        }
      },
      isCurrentRoute(routeName) {
        return this.currentRouteName === routeName
      },
    },
  }
</script>

<style scoped>
  /* .firstMenuItems::after:last-child {
    content: '';
    background-color: red;
  } */
</style>
