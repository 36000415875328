import Vue from 'vue'
import Vuex from 'vuex'
import gql from 'graphql-tag'

import account from './account'
import alerts from './alerts'
import billing from './billing'
import discounts from './discounts'
import equipments from './equipments'
import paymentMethods from './paymentMethods'
import paymentWidget from './paymentWidget'
import statements from './statements'
import support from './support'
import systemNotifications from './systemNotifications'
import customerPrograms from './customerPrograms'
import appointments from './appointments'
import mobile from './mobile'
import broadbandLabels from './broadbandLabels'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,

  modules: {
    account,
    alerts,
    billing,
    discounts,
    equipments,
    paymentMethods,
    paymentWidget,
    statements,
    support,
    systemNotifications,
    customerPrograms,
    appointments,
    mobile,
    broadbandLabels,
  },

  state: {
    login: {
      email: null,
      name: null,
      username: null,
    },
    appIsReady: false,
    appIsProcessing: false,
    switches: {},
    switchData: {},
    modulesStatus: {
      account: false,
      billing: false,
      discounts: false,
      equipments: false,
      paymentMethods: false,
      statements: false,
      support: false,
      outages: false,
      customerPrograms: false,
      appointments: false,
      mobile: false,
      broadbandLabels: false,
    },
  },

  getters: {
    ordergatewayUrl() {
      return import.meta.env.VITE_ORDERGATEWAY_URL || 'https://upgrade.breezeline.com/'
    },
    manualupgradeUrl() {
      return (
        import.meta.env.VITE_ORDERGATEWAY_MANUAL_UPGRADE_URL ||
        'https://upgrade.breezeline.com/manual-upgrade'
      )
    },
  },

  mutations: {
    LOGIN({ login }, payload) {
      login = Object.assign(login, payload)
      delete login.__typename
    },
    SHOW_APP(state) {
      state.appIsReady = true
    },
    LOCK_APP(state) {
      state.appIsProcessing = true
    },
    UNLOCK_APP(state) {
      state.appIsProcessing = false
    },
    SET_SWITCH({ switches }, { key, value = false }) {
      if (value) {
        Vue.set(switches, key, value)
      } else {
        Vue.delete(switches, key)
      }
    },
    SET_SWITCH_DATA(state, payload) {
      state.switchData = payload
    },
    SET_MODULE_STATUS(state, { module, status }) {
      state.modulesStatus[module] = status
    },
  },

  actions: {
    initializeAll({ dispatch, commit }, { routeTo }) {
      dispatch('account/initialize')
      dispatch('billing/initialize')
      dispatch('discounts/initialize')
      dispatch('paymentMethods/initialize')
      dispatch('statements/initialize')
      dispatch('equipments/initialize')
      dispatch('customerPrograms/initialize')
      dispatch('appointments/initialize')
      dispatch('mobile/initialize')
      dispatch('broadbandLabels/initialize')
      dispatch('support/initialize')
      commit('SHOW_APP')

      // note: we cannot wait for all these to settle before showing the app
      // return Promise.allSettled([
      //   dispatch('account/initialize'),
      //   dispatch('billing/initialize'),
      //   dispatch('discounts/initialize'),
      //   dispatch('paymentMethods/initialize'),
      //   dispatch('statements/initialize'),
      //   dispatch('equipments/initialize'),
      // ])
      // .then(all => {
      //   commit('SHOW_APP')
      // })
      // .catch((e) => {
      //   window.Sentry.captureException(e)
      //   // routeTo('server-error', true)
      // })
    },
    logout({ commit }) {
      commit('LOCK_APP')

      return window.$apollo
        .mutate({
          mutation: gql`
            mutation {
              logout
            }
          `,
        })
        .then((r) => {
          if (r.data?.logout) {
            // allow time for the mutation to clear the cookies
            setTimeout(() => {
              window.location.href = decodeURIComponent(r.data.logout)
            }, 1000)
          }
        })
        .catch(() => {
          window.Sentry.captureException(new Error('Unable to log user out!!'))
        })
    },
    setSwitch({ commit }, payload) {
      commit('SET_SWITCH', payload)
    },
    setSwitchData({ commit }, payload) {
      commit('SET_SWITCH_DATA', payload)
    },
    moduleIsReady({ commit }, { name, result }) {
      commit('SET_MODULE_STATUS', {
        module: name,
        status: result,
      })
    },
  },
})
