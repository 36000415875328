<template>
  <div class="space-y-4">
    <div
      v-if="heading || subheading"
      class=""
    >
      <div
        v-if="subheading && subheading.length > 0"
        class="text-base"
        v-html="subheading"
      ></div>
    </div>
    <div class="space-y-4">
      <slot></slot>
    </div>
    <div class="flex flex-row justify-between">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step',
    props: {
      heading: {
        type: String,
        default: null,
      },
      subheading: {
        type: String,
        default: null,
      },
    },
  }
</script>
