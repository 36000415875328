<template>
  <BzlModal
    label="Payment History"
    @sl-after-hide="$emit('dismiss')"
    class="space-y-4 p-4"
    v-if="payment"
  >
    <div class="space-y-4">
      <div class="space-y-2">
        <div class="font-bold">
          {{
            isScheduled
              ? isPromiseToPay
                ? 'Promise To Pay Payment'
                : 'Scheduled Payment'
              : 'Payment Amount'
          }}
        </div>
        <AppPanel
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center justify-between gap-x-4">
              {{ isScheduled ? 'Amount To Pay' : 'Amount Paid' }}
            </div>
            <div>
              <span class="font-bold text-salmon">${{ Math.abs(payment.amount).toFixed(2) }}</span>
            </div>
          </div>
        </AppPanel>
      </div>

      <div class="space-y-2">
        <div class="block text-base font-bold">Payment Date</div>
        <AppPanel
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center justify-between gap-x-4">
              {{ isScheduled ? (isPromiseToPay ? 'Promise To Pay' : 'Scheduled On') : 'Paid On' }}
            </div>
            <div>
              {{ toFriendlyDate(payment.date, 'full-truncated') }}
            </div>
          </div>
        </AppPanel>
      </div>

      <div class="space-y-2">
        <div class="block text-base font-bold">Payment Method</div>

        <AppPanel
          v-if="isScheduled && paymentMethod"
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div v-if="paymentMethod.paymentType === 'CARD'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-credit-card text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>
              {{ paymentMethod.accountHolder }}<br />
              Card # {{ paymentMethod.lastFour }}<br />
              Expires {{ paymentMethod.expiresOn }}
            </div>
          </div>
          <div v-if="paymentMethod.paymentType === 'EFT'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-landmark text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>
              {{ paymentMethod.accountHolder }}<br />
              Acct # {{ paymentMethod.accountNumber }}<br />
              Routing # {{ paymentMethod.routingNumber }}
            </div>
          </div>
        </AppPanel>

        <AppPanel
          v-else-if="paymentMethod"
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div v-if="paymentMethod.paymentType === 'CARD'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-credit-card text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>Card # {{ paymentMethod.lastFour }}</div>
          </div>
          <div v-else-if="paymentMethod.paymentType === 'EFT'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-landmark text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>Acct # {{ paymentMethod.accountNumber }}</div>
          </div>
          <div v-else>N/A</div>
        </AppPanel>
      </div>
    </div>

    <div v-if="payment && isScheduled">
      <p class="bg-yellow-100 p-4 text-center text-sm">
        <i class="fas fa-exclamation-circle"></i>
        Payments cannot be cancelled on or after scheduled payment date.<br />
        Please allow 1-2 days for processing.
      </p>
    </div>

    <div class="mt-4 flex w-full flex-row justify-between">
      <BzlButton
        v-if="isCancellable"
        variant="text"
        @click="confirmToCancel()"
      >
        Cancel this payment
      </BzlButton>
      <BzlButton
        variant="primary"
        @click="$emit('dismiss')"
      >
        Close
      </BzlButton>
    </div>
    <template #inner-modal>
      <div
        v-if="modals.cancelPayment"
        class="absolute inset-0 -top-4 flex h-auto w-full items-center justify-center bg-black bg-opacity-75 p-4"
      >
        <div
          class="overflow-y-none relative max-h-full w-full max-w-prose space-y-4 rounded-md bg-white p-4 text-navy shadow"
        >
          <div
            v-if="isPromiseToPay"
            class="text-center text-lg font-bold"
          >
            By canceling your Promise to Pay agreement, you may be subject to service disruption
            dependent on the status of your account and if any past due balance remains.
          </div>
          <div
            v-else
            class="text-center text-lg font-bold"
          >
            Are you sure you want to<br />
            cancel this scheduled payment?
          </div>
          <div class="flex w-full flex-row justify-between">
            <BzlButton
              variant="text"
              @click="approveCancellation()"
            >
              Yes, cancel this payment
            </BzlButton>
            <BzlButton
              variant="primary"
              @click="rejectCancellation()"
            >
              No, go back
            </BzlButton>
          </div>
        </div>
      </div>
    </template>
  </BzlModal>
</template>

<script>
  export default {
    name: 'ViewPayment',
    props: {
      payment: {
        type: Object,
        default: () => ({}),
      },
      promiseToPay: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      modals: {
        cancelPayment: false,
      },
    }),
    computed: {
      paymentMethod() {
        return this.payment?.paymentMethod
      },
      isScheduled() {
        return Boolean(this.payment?.isScheduled)
      },
      isCancellable() {
        // scheduled payments with date of today are not cancellable
        return this.isScheduled && !this.isTodayOrBefore(this.payment?.date)
      },
      isPromiseToPay() {
        return this.promiseToPay?.date === this.payment.date
      },
    },
    methods: {
      confirmToCancel() {
        this.modals.cancelPayment = true
      },
      rejectCancellation() {
        this.modals.cancelPayment = false
      },
      approveCancellation() {
        this.$emit('cancel-payment', this.payment, (r) => {
          if (r) {
            this.$emit('dismiss')
          } else {
            this.modals.cancelPayment = false
          }
        })
      },
    },
  }
</script>
