<template>
  <div>
    <form
      ref="widget-initiator"
      :action="session.gateway"
      method="POST"
      target="externalForm"
    >
      <input
        type="hidden"
        name="type"
        value="button"
      />
      <input
        type="hidden"
        name="pw_session_id"
        :value="session.sessionId"
      />
      <input
        type="hidden"
        name="pw_nonce"
        :value="session.nonce"
      />
      <input
        type="hidden"
        name="pw_payment_amount"
        :value="paymentAmount"
      />
      <input
        type="hidden"
        name="callback"
        :value="callback"
      />
      <input
        type="hidden"
        name="pw_business_unit"
        :value="session.businessUnit"
      />
      <input
        type="hidden"
        name="pw_currency"
        value="USD"
      />
      <input
        type="hidden"
        name="pw_channel"
        value="SelfCarePortal"
      />
      <input
        type="hidden"
        name="pw_authorization_method"
        value="WEB"
      />
      <input
        type="hidden"
        name="pw_entry_method"
        value="WEB"
      />
      <input
        type="hidden"
        name="pw_cfo_code"
        value="WP"
      />
      <input
        type="hidden"
        name="pw_show_save"
        value="false"
      />
      <input
        type="hidden"
        name="pw-create-profile"
        :value="createProfile"
      />
      <input
        type="hidden"
        name="class"
        value="ccwBtn"
      />
      <input
        type="hidden"
        name="data-id"
        value="modal0"
      />
      <input
        type="hidden"
        name="request_id"
        :value="uuid"
      />
    </form>
    <AppModal
      v-if="showModal"
      inner-class="h-full md:h-auto flex flex-col p-0 space-y-0"
    >
      <div class="relative">
        <div
          ref="widget-wrapper"
          class="h-full overflow-y-auto overflow-x-hidden"
        >
          <i class="absolute left-0 top-0 h-8 w-full bg-white"></i>
          <iframe
            name="externalForm"
            :width="externalFormWidth"
            height="750"
          ></iframe>
          <i class="absolute bottom-0 left-0 h-8 w-full bg-white"></i>
        </div>
      </div>
      <div class="pb-4 text-center">
        <BzlButton
          variant="primary"
          @click="$emit('dismiss')"
        >
          Close
        </BzlButton>
      </div>
    </AppModal>
  </div>
</template>

<script>
  /*
        This component is used by OneTimePayment and AddPaymentMethod to create separate, different
        instances of the external (CSG) Payment Widget form in a modal.

        This is NOT the component for making normal and future payments.
    */
  export default {
    name: 'CSGWidget',
    props: {
      showModal: {
        type: Boolean,
        required: true,
        default: false,
      },
      session: {
        type: Object,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      paymentAmount: {
        type: [Number, String],
        default: 1,
      },
      createProfile: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      externalFormWidth: 500, // this will be the default width on desktop
    }),
    watch: {
      showModal(newVal) {
        if (newVal) {
          setTimeout(() => {
            // a neat trick for DOM rendering timing issues
            this.externalFormWidth = this.$refs['widget-wrapper'].clientWidth
            this.$refs['widget-initiator'].submit()
            window.addEventListener('message', this.callback)
          }, 0)
        } else {
          // notice this is in this watcher, not in destroyed(), because modal is NOT the root component
          window.removeEventListener('message', this.callback)
        }
      },
    },
    methods: {
      callback(e) {
        const gateway = new URL(import.meta.env.VITE_MYACCOUNT_PAYMENT_WIDGET_GATEWAY)

        if (e?.origin === gateway.origin) {
          const data = JSON.parse(e.data)

          if (!import.meta.env.PROD) {
            console.log(data)
          }

          if (data?.event === 'success') {
            if (this.createProfile) {
              this.trackEvent('SPM_ADD_PAYMENT_METHOD_SUCCESS')
              this.$emit('added-payment-method')

              this.$store.dispatch(
                'alerts/setAlert',
                {
                  variant: 'success',
                  message: 'Your payment method has been saved.',
                },
                { root: true }
              )
            } else {
              this.trackEvent('SPM_ADD_PAYMENT_METHOD_WITH_PAYMENT_SUCCESS')
              this.$store.dispatch(
                'alerts/setAlert',
                {
                  variant: 'success',
                  message: 'Thank you for your payment.',
                },
                { root: true }
              )
            }
          } else {
            // data.reason_code === "1000" // Payment method is invalid.

            if (this.createProfile) {
              this.$store.dispatch(
                'alerts/setAlert',
                {
                  message: 'Unable to save payment method. Please contact Support.',
                },
                { root: true }
              )
            } else {
              this.$store.dispatch(
                'alerts/setAlert',
                {
                  message: 'Unable to make one-time payment. Please contact Support.',
                },
                { root: true }
              )
            }

            this.$emit('error', `${data.reason_code} ${data.reason_description}`)
          }

          this.$emit('dismiss')
        }
      },
    },
  }
</script>
