<template>
  <div
    class="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-75"
  >
    <img
      src="./pinwheel.png"
      class="animate-spin"
    />
  </div>
</template>

<script>
  export default {
    name: 'BzlLoadingOverlay',
  }
</script>

<style scoped>
  .animate-spin {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
