<template>
  <AppPanel
    id="StatementHistory"
    :collapsible="true"
    label="Statement History"
    v-on="$listeners"
    @sl-show="handleDetailsToggle"
  >
    <div
      v-if="statements.length"
      class="grid grid-cols-3 gap-4"
    >
      <div class="col-span-3 flex flex-row items-center justify-start gap-x-2">
        <div class="w-full">
          <BzlDropdown class="w-full lg:w-1/4">
            <template #trigger>
              <BzlButton
                slot="trigger"
                :m-full="true"
                caret
                variant="text"
                class="text-navy-blue w-full rounded border border-blue bg-white px-4 lg:w-56"
              >
                {{ selectedYear }} Statements
              </BzlButton>
            </template>
            <BzlMenu>
              <BzlMenuItem
                v-for="year in availableYears"
                :key="year"
                @click="selectYear(year)"
              >
                {{ year }} Statements
              </BzlMenuItem>
            </BzlMenu>
          </BzlDropdown>
        </div>
      </div>
      <div class="font-bold">Statement Date</div>
      <div class="font-bold">Amount</div>
      <div class="justify-self-end font-bold">Details</div>

      <hr class="col-span-3" />
      <div class="col-span-3 grid gap-4">
        <div
          v-for="item in filteredStatements"
          :key="item.datePosted"
        >
          <div class="grid grid-cols-3 gap-4">
            <div class="cursor-pointer font-bold hover:text-salmon">
              <a
                href="#"
                class="underline"
                @click.prevent="download(item)"
                >{{ toFriendlyDate(item.datePosted, 'full-truncated-no-year') }}</a
              >
            </div>
            <div class="font-book text-sm">${{ item.amountDue.toFixed(2) }}</div>
            <a
              href="#"
              class="cursor-pointer justify-self-end underline hover:text-salmon"
              @click.prevent="
                $emit('download-statement', item), trackEvent('trigger_statement_details')
              "
            >
              See details
            </a>
          </div>
        </div>
      </div>
      <hr class="col-span-3" />
      <div class="col-span-3 text-center">
        <div class="mb-1 font-bold text-navy-dark">Need to see additional statement history?</div>
        <p class="text-sm">
          Call Customer Service at
          <a
            href="tel:18885369600"
            class="underline"
            >1-888-536-9600</a
          >.
        </p>
      </div>
    </div>
    <div
      v-else
      class="text-center"
    >
      No statements found.
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'StatementHistory',

    props: {
      statements: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      selectedYear: null,
      selectedItem: null,
    }),
    computed: {
      availableYears() {
        return this.statements.map((s) => s.year)
      },
      filteredStatements() {
        return this.statements.find((s) => s.year === this.selectedYear)?.monthlies || []
      },
    },
    mounted() {
      if (this.statements.length > 0) {
        this.selectedYear = this.statements[0].year
      }
    },
    methods: {
      selectYear(year) {
        this.selectedYear = year
      },
      download(invoice) {
        this.trackEvent('trigger_view_statements_clicked', invoice.datePosted)
        this.$emit('download-statement', invoice)
      },
      handleDetailsToggle(isOpen) {
        this.trackEvent('trigger_statement_history_clicked')
      },
    },
  }
</script>

<style scoped>
  .BzlButton[variant='text']::part(base) {
    @apply flex justify-between no-underline;
  }

  .BzlButton[variant='text']::part(label) {
    @apply py-2 text-base;
  }

  .BzlButton[variant='text']::part(prefix) {
    display: none !important;
  }

  .BzlButton[variant='text']::part(caret) {
    @apply text-xl;
  }

  .BzlMenu {
    @apply max-w-full border border-blue  text-navy;
  }

  .BzlMenuItem::part(base) {
    @apply w-64 px-4 text-navy-dark hover:bg-blue-lightest lg:w-56;
  }

  .BzlMenuItem::part(checked-icon) {
    @apply hidden;
  }
</style>
