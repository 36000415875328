<template>
  <BzlModal
    label="AutoPay"
    @sl-after-hide="$emit('dismiss')"
  >
    <div>
      <p>
        Please confirm that you want to disable AutoPay. If you do, you’ll have to make future
        payments manually.
      </p>
    </div>
    <hr class="border-gray-light pt-4 lg:mt-8" />
    <div class="flex w-full flex-row justify-between">
      <BzlButton
        variant="primary"
        @click="confirm()"
      >
        Confirm
      </BzlButton>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'DisableAutopay',
    props: {
      willLoseDiscount: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      confirm() {
        this.trackEvent('trigger_unenrolled_autopay')
        this.$emit('confirm', () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
