<template>
  <AppPanel
    id="EquipmentDetails"
    label="Upgrade Your Modem"
    variant="alternate"
    :open="true"
    :collapsible="true"
  >
    <div
      id="EquipmentDetails"
      class="grid grid-cols-1 gap-4"
    >
      <div class="grid grid-cols-1 gap-4">
        <p>
          If you are leasing an Internet modem from Breezeline, please contact our Customer Support
          Center to upgrade your equipment. When you lease a modem through Breezeline, you will get
          the best speeds and performance from your Internet connection. However, if you are using
          your own modem and would like to replace it with another one you've purchased, you can do
          that without contacting us by following these steps:
        </p>
        <ol class="list-inside list-decimal py-4">
          <li class="mb-2">
            Confirm that the new equipment is on the
            <a
              href="https://www.breezeline.com/assets/documents/breezeline-approved-devices.pdf"
              target="_blank"
              class="underline"
              >list of approved devices</a
            >
            compatible with Breezeline Internet
          </li>
          <li class="mb-2">Unplug your old modem and connect the new one</li>
          <li class="mb-2">
            After it's connected, follow the step-by-step instructions to activate your new
            equipment
          </li>
        </ol>
        <p>
          Alternately, it you'd like to register your new device before you plug it in, just have
          the MAC address available and visit the
          <a
            href="https://selfcare.breezeline.com:8443/walledgarden/"
            target="_blank"
            class="underline"
            >activation portal.</a
          >
        </p>
        <div></div>
      </div>
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'UpgradeYourModem',
    props: {},
    methods: {},
  }
</script>
