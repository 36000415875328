<template>
  <sl-button
    v-bind="{ ...$props, ...$attrs }"
    :class="computedClasses"
    class="AppButton"
    v-on="$listeners"
  >
    <slot></slot>
  </sl-button>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/button/button.js'

  export default {
    name: 'AppButton',
    props: {
      /* Custom props */
      mFull: {
        // is full width on mobile?
        type: Boolean,
        default: false,
      },

      /* Native props */
      variant: {
        type: String,
        default: 'primary',
        validator: (val) =>
          ['default', 'primary', 'success', 'neutral', 'warning', 'danger', 'text'].includes(val),
      },
      size: {
        type: String,
        default: 'medium',
        validator: (val) => ['small', 'medium', 'large'].includes(val),
      },
      caret: {
        // Indicates the button has a "dropdown" menu
        type: Boolean,
        default: false,
      },
      disabled: {
        // Disabled, clicks suppressed
        type: Boolean,
        default: false,
      },
      loading: {
        // Loading spinner (keeps button width, hide contents)
        type: Boolean,
        default: false,
      },
      outline: {
        // Borders
        type: Boolean,
        default: false,
      },
      pill: {
        // Full rounded corners
        type: Boolean,
        default: true, // SL default is false
      },
      circle: {
        // Forces the button to become a circle
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: 'button',
        validator: (val) => ['button', 'submit', 'reset'].includes(val),
      },
      name: {
        // Optional name, ignored if href is set
        type: String,
        default: null,
      },
      value: {
        // Optional value, ignored if href is set
        type: String,
        default: null,
      },
      href: {
        // Render the button as an anchor tag
        type: String,
        default: null,
      },
      target: {
        type: String,
        default: null,
        validator: (val) => ['_blank', '_parent', '_self', '_top'].includes(val),
      },
      download: {
        type: Boolean,
        default: false,
      },
      form: {
        type: String,
        default: null,
      },
      formAction: {
        type: String,
        default: null,
      },
      formMethod: {
        type: String,
        default: null,
        validator: (val) => ['post', 'get'].includes(val),
      },
      formNoValidate: {
        type: Boolean,
        default: false,
      },
      formTarget: {
        type: String,
        default: null,
        validator: (val) => ['_blank', '_parent', '_self', '_top'].includes(val),
      },
    },
    computed: {
      computedClasses() {
        const computedClasses = []

        if (this.mFull) {
          computedClasses.push(`w-full lg:w-auto lg:mx-auto`)
        }

        return computedClasses.join(' ')
      },
    },
  }
</script>

<style>
  .AppButton::part(base) {
    @apply font-bold;
  }
</style>
