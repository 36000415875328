<template>
  <Step heading="Step 3: Confirmation">
    <div
      v-if="responseStatus"
      class=""
    >
      <div v-if="isFuture">
        Your scheduled payment has been submitted and will be processed on
        <strong>{{ toFriendlyDate(paymentDate, 'full-truncated') }}</strong
        >.
      </div>
      <div v-else>
        Your payment has been submitted and will be processed within 24 hours. Please allow 2-4
        business days for it to reflect on your account.
      </div>
    </div>
    <div
      v-else
      class="text-red-600 text-center"
    >
      <i class="fas fa-exclamation-circle"></i>
      There is a problem processing your payment. Please confirm all relevant information are
      accurate. If this problem persists, please contact Customer Service at
      <a
        href="tel:18885369600"
        class="underline"
        >1-888-536-9600</a
      >.
    </div>
    <template #footer>
      <BzlButton
        variant="primary"
        @click="$emit('close')"
      >
        Close
      </BzlButton>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'

  export default {
    name: 'PaymentWizardStep3',
    components: {
      Step,
    },
    props: {
      paymentDate: {
        type: String,
        required: true,
      },
      isFuture: {
        type: Boolean,
        default: false,
      },
      responseStatus: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
