<template>
  <BzlModal
    @sl-after-hide="$emit('dismiss')"
    label="Enroll in Paperless Billing"
  >
    <div class="space-y-4">
      <p>
        Please provide a Preferred Email address where you would like to receive bills & statements.
      </p>
      <div class="space-y-2">
        <label class="block"> Preferred E-mail </label>
        <input
          type="email"
          class="w-full rounded bg-gray-light text-center font-bold"
          :value="email"
          disabled
        />
      </div>
      <hr class="border-gray-light pt-4 lg:mt-8" />
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="$emit('dismiss')"
        >
          Cancel
        </BzlButton>
        <BzlButton
          variant="primary"
          @click="confirm()"
        >
          Confirm
        </BzlButton>
      </div>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'EnableEcosave',
    props: {
      email: {
        type: String,
        default: '',
      },
    },
    methods: {
      confirm() {
        this.trackEvent('ES_ENABLE_ECOSAVE_CLICKED')
        this.$emit('confirm', () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
