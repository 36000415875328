<template>
  <PaymentMethods
    :payment-methods="$store.state.paymentMethods.allPaymentMethods"
    :autopay-payment-method="$store.state.discounts.autopay.paymentMethod"
    :max-payment-methods="maxPaymentMethods"
    :is-autopay-available="$store.state.discounts.autopay.isAvailable"
    @add="showAddPaymentMethod()"
    @delete="confirmToDeletePaymentMethod"
    @set-as-default="$store.dispatch('paymentMethods/setAsDefault', $event)"
    @set-as-autopay="confirmToSetAsAutopay"
    @disable-autopay="$emit('disable-autopay')"
  >
    <AddPaymentMethod
      :show-modal="modals.addPaymentMethod"
      :session="$store.state.paymentWidget.session"
      :uuid="generateUUID()"
      @added-payment-method="modals.enableAutopay = true"
      @error="onWidgetError($event)"
      @dismiss="hideAddPaymentMethod()"
    ></AddPaymentMethod>

    <DeletePaymentMethod
      :open="modals.deletePaymentMethod"
      :payment-method="paymentMethodToDelete"
      :autopay-payment-method="$store.state.discounts.autopay.paymentMethod"
      :scheduled-payments="$store.getters['billing/getScheduledPayments']"
      @approve="approveToDeletePaymentMethod"
      @dismiss="modals.deletePaymentMethod = false"
    ></DeletePaymentMethod>

    <SetAsAutopay
      :open="modals.setAsAutopay"
      :payment-methods="$store.state.paymentMethods.allPaymentMethods"
      :preselected-payment-method.sync="paymentMethodToSetAsAutopay"
      @confirm="approveToSetPaymentMethodAsAutopay"
      @dismiss="modals.setAsAutopay = false"
    ></SetAsAutopay>

    <EnableAutopay
      :open="modals.enableAutopay"
      :payment-methods="$store.state.paymentMethods.allPaymentMethods"
      :preselected-payment-method.sync="paymentMethodToSetAsAutopay"
      @confirm="approveToSetPaymentMethodAsAutopay"
      @dismiss="modals.enableAutopay = false"
    ></EnableAutopay>
  </PaymentMethods>
</template>

<script>
  import PaymentMethods from '../../components/PaymentMethods/index.vue'
  import AddPaymentMethod from '../../components/PaymentMethods/add.vue'
  import DeletePaymentMethod from '../../components/PaymentMethods/delete.vue'
  import SetAsAutopay from '../../components/PaymentMethods/set-as-autopay.vue'
  import EnableAutopay from '../../components/PaymentMethods/enable-autopay.vue'

  export default {
    name: 'PaymentMethodsSection',
    components: {
      PaymentMethods,
      AddPaymentMethod,
      DeletePaymentMethod,
      SetAsAutopay,
      EnableAutopay,
    },
    data: () => ({
      modals: {
        addPaymentMethod: false,
        deletePaymentMethod: false,
        setAsAutopay: false,
        enableAutopay: false,
      },
      paymentMethodToDelete: null,
      paymentMethodToSetAsAutopay: null,
      switches: {},
      switchData: {},
      switched: false,
    }),
    computed: {
      maxPaymentMethods() {
        return Number(import.meta.env.VITE_MAX_PAYMENT_METHODS)
      },
    },
    watch: {
      switches(newVal) {
        if (newVal['add-payment-method']) {
          this.showAddPaymentMethod()
          this.switched = true
          this.$store.commit('SET_SWITCH', {
            key: 'add-payment-method',
          })
        }
      },
    },
    mounted() {
      this.switches = this.$store.state.switches
    },
    methods: {
      showAddPaymentMethod() {
        return this.$store
          .dispatch('paymentWidget/getWidgetSession')
          .then((r) => (this.modals.addPaymentMethod = r && true))
      },
      hideAddPaymentMethod() {
        return this.$store
          .dispatch('paymentMethods/refetchPaymentMethods')
          .then(() => {
            this.modals.addPaymentMethod = false
          })
          .then(() => {
            if (this.switched) {
              this.$store.commit('SET_SWITCH', {
                key: this.$store.state.switchData?.returnTo ?? 'show-payment-wizard',
                value: true,
              })
            }
            this.switched = false
          })
      },
      confirmToDeletePaymentMethod(whichPM) {
        this.paymentMethodToDelete = whichPM
        this.modals.deletePaymentMethod = true
      },
      approveToDeletePaymentMethod(whichPM, callback) {
        return this.$store.dispatch('paymentMethods/deletePaymentMethod', whichPM).then(callback)
      },
      confirmToSetAsAutopay(whichPM) {
        this.trackEvent('A_CHANGE_AUTOPAY_CLICKED')
        this.paymentMethodToSetAsAutopay = whichPM
        this.modals.setAsAutopay = true
      },
      approveToSetPaymentMethodAsAutopay(whichPM, callback) {
        return this.$store.dispatch('discounts/enableAutopay', whichPM).then(callback)
      },
      onWidgetError(e) {
        console.log(e)
      },
    },
  }
</script>
