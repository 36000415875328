<template>
  <div class="space-y-4">
    <div
      v-if="heading && heading.length > 0"
      class="text-xl font-bold lg:text-2xl"
    >
      <hr class="mt-4" />
    </div>
    <div class="space-y-8">
      <slot></slot>
    </div>
    <div class="flex flex-row justify-around">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Step',
    props: {
      heading: {
        type: String,
        default: null,
      },
    },
  }
</script>
