import Vue from 'vue'
import * as Sentry from '@sentry/vue'

if (import.meta.env.VITE_SENTRY_DSN === '') {
  console.log('No Sentry DSN is set. Will not be reporting to Sentry')
}

Sentry.init({
  Vue,
  environment: import.meta.env.MODE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  sampleRate: parseFloat(import.meta.env.VITE_SENTRY_SAMPLE_RATE) || 0.01,
  
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration(),
  ],
  release: __RELEASE__,
  allowUrls: [/https?:\/\/([\w-]+\.)*breezeline\.com/],
})

window.Sentry = Sentry
