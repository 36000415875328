<template>
  <sl-dropdown
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    class="BzlDropdown"
  >
    <slot name="trigger"></slot>
    <slot></slot>
  </sl-dropdown>
</template>

<script>
  // https://shoelace.style/components/dropdown
  import '@shoelace-style/shoelace/dist/components/dropdown/dropdown.js'

  export default {
    name: 'BzlDropdown',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      placement: {
        type: String,
        default: 'bottom-start',
        validator: (val) =>
          [
            'top',
            'top-start',
            'top-end',
            'bottom',
            'bottom-start',
            'bottom-end',
            'right',
            'right-start',
            'right-end',
            'left',
            'left-start',
            'left-end',
          ].includes(val),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      stayOpenOnSelect: {
        type: Boolean,
        default: false,
      },
      distance: {
        type: Number,
        default: 0,
      },
      skidding: {
        type: Number,
        default: 0,
      },
      hoist: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
