<template>
  <div
    id="app"
    class="min-h-screen"
  >
    <template v-if="$route.meta.isAux">
      <RouterView name="main"></RouterView>
    </template>
    <template v-else>
      <template v-if="$store.state.appIsReady">
        {{ ujetIsReadyToDisplay }}
        <div
          class="lg:grid lg:h-screen lg:grid-cols-[230px_1fr] lg:grid-rows-[min-content_1fr] xl:grid-cols-[330px_1fr]"
        >
          <div class="col-span-full">
            <BzlSystemNotifications
              v-if="hasNotifications"
              :open="notificationsVisible && hasNotifications"
              :alerts="$store.state.systemNotifications.notifications"
              @close-system-notifications="closeNotifications()"
            ></BzlSystemNotifications>
          </div>
          <div class="bg-white">
            <AppMenu
              :current-route-name="$route.name"
              @route-to="routeTo($event)"
              @logout="$store.dispatch('logout')"
            ></AppMenu>
          </div>
          <div
            class="overflow-auto"
            data-app-pane
          >
            <div
              id="main-view"
              class="container space-y-4 pb-56 pt-4 lg:col-start-2 lg:pb-32 lg:pt-16"
            >
              <RouterView
                name="main"
                @route-to="routeTo($event)"
                @logout="$store.dispatch('logout')"
              ></RouterView>
            </div>
          </div>
        </div>
      </template>
      <AppAlert
        v-if="$store.state.alerts.alert"
        v-bind="$store.state.alerts.alert"
      ></AppAlert>
      <BzlLoadingOverlay v-if="$apollo.loading || $store.state.appIsProcessing"></BzlLoadingOverlay>
    </template>
  </div>
</template>

<script>
  import AppMenu from './components/AppMenu/index.vue'
  import AppAlert from './components/AppAlert/index.vue'
  import { createUjetObj } from './ccai/ccai'

  export default {
    name: 'MyAccount',
    components: {
      AppMenu,
      AppAlert,
    },
    data() {
      return {
        closedNotifications: false,
      }
    },
    computed: {
      notificationsVisible() {
        return !this.closedNotifications && this.$store.getters['systemNotifications/isVisible']
      },
      hasNotifications() {
        return this.$store.getters['systemNotifications/hasNotifications']
      },
      //added this method so that once the required data is fully loaded, create ujetObj
      ujetIsReadyToDisplay() {
        let ujetData = {
          state: this.$store.state.account.serviceAddress.state,
          email: this.$store.state.login.email,
          name:
            this.$store.state.account.customerInfo.firstName +
            ' ' +
            this.$store.state.account.customerInfo.lastName,
        }
        if (ujetData.state && ujetData.email && !ujetData.name.includes('null'))
          createUjetObj(ujetData)
      },
    },
    created() {
      window.$apollo = this.$apollo
      this.$store.commit('LOGIN', this.$root.user)
    },
    mounted() {
      this.checkIfBannerNeedsToOpen(['referral', 'loyalty'])
      console.info(`v${__RELEASE__}`)

      if (!this.$route.meta.isAux) {
        this.$store.dispatch('initializeAll', { routeTo: this.routeTo })
      }

      // if we've closed the notifications manually, set the local tracking
      // variable
      if (this.hasClosedNotifications()) {
        this.closedNotifications = true
      }

      // this.$store.dispatch('systemNotifications/addNotification', {
      //   title: 'Demo!',
      //   subtitle: `This is a demo!`,
      // })
      // demo to add notifications and clear the local state
      window.setTimeout(this.addNotifications, 5000)
    },
    methods: {
      routeTo(name, replace = false) {
        if (name !== this.$route.name) {
          this.$router.push({ name, replace })
        }
      },
      // Below: proof of concept as to how one might deal with persistent hiding
      // of notifications
      hasClosedNotifications() {
        return JSON.parse(window.localStorage.getItem('notificationsHidden'))
      },
      convertUTC() {
        let monthArr = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ]
        // Take the etr which is in UTC ISO format and convert it to local date obj
        let etr = new Date(this.$store.state.account.outageInfo?.etr)
        let month = monthArr[etr.getMonth()]
        let day = etr.getDate()
        let hours = etr.getHours()
        let mins = etr.getMinutes()
        let AMorPM = hours >= 12 ? 'PM' : 'AM'

        //Format the day, hour, and minutes, to be displayted properly
        day =
          day === 1 || day === 21 || day === 31
            ? `${day}st`
            : day === 2 || day === 22
            ? `${day}nd`
            : day === 3 || day === 23
            ? `${day}rd`
            : `${day}th`
        hours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours
        mins = mins < 10 ? `0${mins}` : mins
        return `${hours}:${mins} ${AMorPM}, ${month} ${day}`
      },
      checkIfPastBannerLastDay(bannerName) {
        bannerName.forEach((type) => {
          let today = new Date()
          let bannerDismissed = window.localStorage.getItem(`${type}BannerDismissed`)
          let closeDate = window.localStorage.getItem(`${type}BannerLastDay`)

          // If today is greater than the closeDate, automatically close the banner
          if (closeDate && today > new Date(closeDate)) {
            window.localStorage.setItem(`${type}BannerDismissed`, 'true')
          }
        })
      },
      checkIfBannerNeedsToOpen(bannerName) {
        bannerName.forEach((type) => {
          let bannerDismissed = window.localStorage.getItem(`${type}BannerDismissed`)
          let closeDate = window.localStorage.getItem(`${type}BannerLastDay`)

          // if bannerDismissed is false the referral codes banner will open
          if (!bannerDismissed) {
            window.localStorage.setItem(`${type}BannerDismissed`, 'false')
          }
          // this sets a date 1 month from now which will dismiss the banner automatically if its still open
          if (!closeDate) {
            let today = new Date()
            window.localStorage.setItem(
              `${type}BannerLastDay`,
              new Date(today.setMonth(today.getMonth() + 1))
            )
          }
        })
      },
      addNotifications() {
        window.localStorage.removeItem('notificationsHidden')
        this.closedNotifications = false
        this.checkIfPastBannerLastDay(['referral', 'loyalty'])
        if (this.$store.state.account.outageInfo?.activeOutage) {
          //get first 5 numbers of the zipcode
          let zip = this.$store.state.account.outageInfo?.zip
          //get the etr string
          let convertedEtr = this.convertUTC()

          this.$store.dispatch('systemNotifications/addNotification', {
            title: `System outage`,
            subtitle: `There is an outage in ${zip}. Estimated time of repair: ${convertedEtr}. Visit `,
          })
        }

        // user must have a referral code and the banner must not have been dismissed automatically or manually
        if (
          this.$store.state.account.customerInfo?.referralCode &&
          localStorage.getItem('referralBannerDismissed') === 'false'
        ) {
          this.$store.dispatch('systemNotifications/addNotification', {
            title: `Refer your friends and family and you could get a $50 statement credit!`,
            subtitle: `Click `,
            variant: 'primary',
          })
        }
        if (
          this.$store.state.customerPrograms.loyaltyInfo?.availability?.available &&
          localStorage.getItem('loyaltyBannerDismissed') === 'false'
        ) {
          this.$store.dispatch('systemNotifications/addNotification', {
            title: `Join Breezeline Rewards today to access a variety of rewards towards dining, wellness, events and much more!`,
            subtitle: `Click`,
            variant: 'primary',
          })
        }

        // this.$store.dispatch('systemNotifications/addNotification', {
        //   title: 'RED ALERT!',
        //   subtitle: `FIRE EVERYTHING WE'VE GOT!`,
        // })

        // this.$store.dispatch('systemNotifications/addNotification', {
        //   title: 'Oh, OK',
        //   subtitle: `We're good now`,
        // })
      },
      closeNotifications() {
        window.localStorage.setItem('notificationsHidden', JSON.stringify(true))
        this.closeNotifications = true

        this.$store.dispatch('systemNotifications/clearNotifications')
        this.$store.dispatch('systemNotifications/hideNotifications')
      },
    },
  }
</script>

<style>
  .container {
    @apply mx-auto px-4 lg:max-w-screen-xl lg:px-8 xl:mx-0 min-[1920px]:max-w-[1920px];
  }

  main {
    @apply grid grid-cols-1 gap-4;
  }

  section {
    @apply space-y-4;
  }

  .alert {
    margin: 10px;
    background-color: blue;
  }
</style>
