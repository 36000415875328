<template>
  <div>
    <div class="space-y-4">
      <div class="space-y-2">
        <div class="font-bold">Payment Amount</div>
        <AppPanel
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center justify-between gap-x-4">
              {{ payment.isScheduled ? 'Amount To Pay' : 'Amount Paid' }}
            </div>
            <div>
              <span class="font-bold text-salmon">${{ Math.abs(payment.amount).toFixed(2) }}</span>
            </div>
          </div>
        </AppPanel>
      </div>

      <div class="space-y-2">
        <div class="block text-base font-bold">Payment Date</div>
        <AppPanel
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div class="flex flex-row items-center justify-between">
            <div class="flex flex-row items-center justify-between gap-x-4">Scheduled On</div>
            <div>
              {{ toFriendlyDate(payment.date, 'full-truncated') }}
            </div>
          </div>
        </AppPanel>
      </div>

      <div class="space-y-2">
        <div class="block text-base font-bold">Payment Method</div>

        <AppPanel
          variant="transparent"
          padding="tight"
          class="border border-gray"
        >
          <div v-if="paymentMethod.paymentType === 'CARD'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-credit-card text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>
              {{ paymentMethod.accountHolder }}<br />
              Card # {{ paymentMethod.lastFour }}<br />
              Expires {{ paymentMethod.expiresOn }}
            </div>
          </div>
          <div v-if="paymentMethod.paymentType === 'EFT'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-landmark text-salmon"></i>
              {{ paymentMethod.displayName }}
            </div>
            <div>
              {{ paymentMethod.accountHolder }}<br />
              Acct # {{ paymentMethod.accountNumber }}<br />
              Routing # {{ paymentMethod.routingNumber }}
            </div>
          </div>
        </AppPanel>
      </div>
    </div>

    <div v-if="payment.isScheduled">
      <p class="bg-yellow-100 p-4 text-center text-sm">
        <i class="fas fa-exclamation-circle"></i>
        Payments cannot be cancelled on or after scheduled payment date.<br />
        Please allow 1-2 days for processing.
      </p>
    </div>

    <div class="mt-4 flex w-full flex-row justify-around">
      <BzlButton
        v-if="isCancellable"
        variant="primary"
        size="large"
        :outline="true"
        @click="$emit('cancel-promise-to-pay', payment)"
      >
        Cancel Payment
      </BzlButton>

      <BzlButton
        variant="primary"
        size="large"
        @click="$emit('modify-promise-to-pay', payment)"
      >
        Modify Promise
      </BzlButton>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ViewPromiseToPayPayment',
    props: {
      payment: {
        type: Object,
        default: () => ({}),
      },
      promiseToPay: {
        type: Object,
        default: () => {},
      },
      open: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      modals: {
        cancelPayment: false,
      },
    }),
    computed: {
      paymentMethod() {
        return this.$store.getters['paymentMethods/getPaymentMethod'](this.payment.paymentMethodId)
      },
      isCancellable() {
        // scheduled payments with date of today are not cancellable
        return this.payment.isScheduled && !this.isTodayOrBefore(this.payment.date)
      },
      isPromiseToPay() {
        return this.promiseToPay?.date === this.payment.date
      },
    },
    methods: {
      rejectCancellation() {
        this.modals.cancelPayment = false
      },
      approveCancellation() {
        this.$emit('cancel-payment', this.payment, (r) => {
          if (r) {
            this.$emit('dismiss')
          } else {
            this.modals.cancelPayment = false
          }
        })
      },
    },
  }
</script>
