<template>
  <BzlModal
    class="AppPromoBannerModal"
    :open="open"
    :no-header="true"
    @sl-hide="$emit('close-mobile-app-banner')"
  >
    <AppPromoBanner
      @close-mobile-app-banner="$emit('close-mobile-app-banner')"
      class="lg:col-span-2"
    ></AppPromoBanner>
  </BzlModal>
</template>

<script>
  import AppPromoBanner from '../AppPromoBanner/index.vue'

  export default {
    name: 'AppPromoBannerModal',
    components: {
      AppPromoBanner,
    },
    props: {
      open: {
        required: true,
        default: false,
        type: Boolean,
      },
    },
  }
</script>

<style scoped>
  .BzlModal::part(panel) {
    @apply p-0 max-md:mx-4 max-md:w-full max-md:max-w-full;
    padding: 0 !important;
  }
</style>
