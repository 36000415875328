<template>
  <div>
    <AppPanel
      label="Appointments"
      spacing="none"
      :noLinkborder="true"
      class="h-full lg:block"
    >
      <div class="flex h-full flex-col items-stretch justify-between">
        <div class="margin flex w-full flex-col gap-4">
          <p>
            You have
            {{ this.$store.state.appointments.appointmentInfo.appointments.length }} upcoming
            appointment:
          </p>
          <div
            class="flex w-full flex-col gap-4 border-b border-gray pb-4 last:border-0"
            v-for="(appointment, index) in this.$store.state.appointments.appointmentInfo
              .appointments"
          >
            <p class="date">
              {{ rearrangeDate(appointment.appointmentDate) }} between
              {{ rearrangeTimeSlot(appointment.timeSlot) }}
            </p>

            <div>
              {{ rearrangeAddress(serviceAddress.addressLine1)
              }}<span v-if="serviceAddress.addressLine2"
                >, {{ rearrangeAddress(serviceAddress.addressLine2) }}</span
              >
              <br />
              {{ serviceAddress.state }}, {{ rearrangeAddress(serviceAddress.city) }}
              {{ serviceAddress.postalCode.slice(0, 5) }}
            </div>
            <a
              href="https://www.breezeline.com/support/internet/installation-checklist"
              target="_blank"
              class="link date"
              >Get ready for your appointment!</a
            >
            <div>
              <BzlButton
                variant="default"
                :href="`https://${appointment.url}`"
                target="_blank"
              >
                View & Manage Appointment
              </BzlButton>
            </div>
          </div>
        </div>
      </div>
    </AppPanel>
  </div>
</template>

<script>
  export default {
    name: 'Appointments',
    props: {
      accountNumber: {
        type: String,
        required: true,
      },
      customerInfo: {
        type: Object,
        required: true,
      },
      serviceAddress: {
        type: Object,
        required: true,
      },
      isSummary: {
        type: Boolean,
        default: false,
        required: false,
      },
      loginEmail: {
        type: String,
        default: '',
        required: false,
      },
    },
    data: () => ({
      url: '#',
    }),
    computed: {},
    methods: {
      rearrangeTimeSlot(time) {
        let arr = [
          time.slice(0, 2) + ':' + time.slice(2, 5),
          time.slice(6, 8) + ':' + time.slice(8, 11),
        ]
        arr = arr.map((element) => {
          if (element.substring(0, 1) === '0') {
            element = element.substring(1)
          }
          return element.slice(-1) === 'P'
            ? element.slice(0, element.length - 1) + 'pm'
            : element.slice(0, element.length - 1) + 'am'
        })
        return arr[0] + ' - ' + arr[1]
      },
      rearrangeDate(date) {
        let weekDays = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ]
        let month = date.slice(2, 4)
        let day = date.slice(4, 6)
        let year = '20' + date.slice(0, 2)
        let newDate = new Date(year, month - 1, day)
        return `${weekDays[newDate.getDay()]}, ${month}/${day}/${year}`
      },
      rearrangeAddress(address) {
        let newAddress = address.split(' ')
        let str = ''
        newAddress.forEach((u, index) => {
          str +=
            u.substring(0, 1).toUpperCase() +
            u.slice(1, u.length).toLowerCase() +
            (index == newAddress.length - 1 ? '' : ' ')
        })
        return str
      },
    },
  }
</script>

<style scoped>
  .btn {
    width: auto;
  }
  .link {
    text-decoration: underline;
  }
  .margin {
    margin: 25px 0 5px 0;
  }
  .date {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }
</style>
