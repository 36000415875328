<template>
  <div
    v-if="
      this.$store.state.broadbandLabels.broadbandLabels[0]?.fccLabel?.frn &&
      !divHTML?.includes('error')
    "
  >
    <AppPanel
      variant="alternate"
      :collapsible="true"
      label="Broadband Facts"
    >
      <p>
        Below is the fixed broadband consumer disclosure presented to you at the time of purchase.
      </p>
      {{ waitforLabels }}
      <div v-html="divHTML"></div>
    </AppPanel>
  </div>
  <AppPanel
    variant="alternate"
    :collapsible="true"
    label="Broadband Facts"
    v-else
  >
    <div>
      Our records indicate that your last purchase of broadband services from Breezeline was made
      before April 10, 2024. Therefore, no Broadband Facts label is available. If you believe this
      is an error, please contact us at 888.536.9600.
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'AccountLabels',
    data: () => ({
      divHTML: null,
    }),
    mounted() {},
    computed: {
      waitforLabels() {
        // https://bzl-api-stg.breezeline.com/api/broadband-labels/frn?frn=F0005937974b68d27f41167a7
        if (this.$store.state.broadbandLabels.broadbandLabels[0]?.fccLabel?.frn) {
          fetch(
            `${import.meta.env.VITE_API_URL}/api/broadband-labels/frn?frn=${
              this.$store.state.broadbandLabels.broadbandLabels[0]?.fccLabel?.frn
            }`,
            {}
          )
            .then((res) => {
              // get the label html
              return res.text()
            })
            .then((html) => {
              // set the label html to this variable to use in the v-html
              this.divHTML = html
            })
            .catch((err) => {
              console.error('Failed to fetch. Error: ', err)
            })
        }
      },
    },
    methods: {},
  }
</script>

<style>
  /* How to hit the various label elements*/
  body .label {
    width: 100%;
    padding: 0;
    max-width: 815px;
  }

  .label-header-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
  }

  /* Left align labels when it hits max width */
  @media screen and (min-width: 480px) {
    body .label {
      text-align: left !important;
      margin: 15px 0;
    }
  }
</style>
