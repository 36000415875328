<template>
  <div>
    <AppPanel
      v-if="displayHeader"
      alignment="center"
      label="Manage & troubleshoot equipment"
    >
      <div>
        <AppPanel variant="alternate">
          <div class="flex flex-col items-center justify-between gap-4 lg:flex-row">
            <div
              v-for="item in itemList"
              :key="item.label"
              class="flex w-full grow flex-col items-center gap-4"
            >
              <BzlIcon
                :customIconName="item.label"
                :src="getIcon(item.iconName)"
                style="font-size: 3rem"
              ></BzlIcon>
              <RouterLink
                v-if="item.routeName"
                :to="{ path: item.routeName }"
              >
                <BzlButton
                  :m-full="true"
                  variant="default"
                >
                  {{ item.label }}
                </BzlButton>
              </RouterLink>
              <BzlButton
                v-else
                :m-full="true"
                variant="default"
                :href="item.url"
                :target="item.target"
              >
                {{ item.label }}
              </BzlButton>
            </div>
          </div>
        </AppPanel>
      </div>
    </AppPanel>
    <AppPanel
      v-else
      variant="alternate"
    >
      <div class="flex flex-col items-center justify-between gap-4 lg:flex-row">
        <div
          v-for="item in itemList"
          :key="item.label"
          class="flex w-full grow flex-row items-center justify-center gap-2"
        >
          <BzlIcon
            :customIconName="item.label"
            style="font-size: 2rem"
            :src="getIcon(item.iconName)"
          ></BzlIcon>
          <RouterLink
            v-if="item.routeName && item.label != 'General Support'"
            :to="{ path: item.routeName }"
            @mousedown.native="gaEvent(item.label)"
            class="w-full lg:w-auto"
          >
            <BzlButton
              :m-full="true"
              variant="default"
            >
              {{ item.label }}
            </BzlButton>
          </RouterLink>
          <a
            v-else
            class="link"
            :href="item.url"
            :target="item.target"
            >{{ item.label }}</a
          >
          <!-- <BzlButton
          style="margin: 0;"
            v-else
            :m-full="true"
            variant="default"
            :href="item.url"
            :target="item.target"
            @mousedown.native="gaEvent(item.label)">
            {{ item.label }}
          </BzlButton> -->
        </div>
      </div>
    </AppPanel>
  </div>
</template>

<script>
  import EquipmentIcon from './equipment-icon.svg'
  import InternetIcon from './internet-icon.svg'
  import RemoteIcon from './remote-icon.svg'
  import ServiceIcon from './service-icon.svg'
  import SpeedIcon from './speed-icon.svg'
  import SupportIcon from './support-icon.svg'
  import UpgradeModemIcon from './upgrade-icon.svg'

  export default {
    name: 'EquipmentHelp',
    props: {
      displayHeader: {
        type: Boolean,
        default: false,
        required: false,
      },
      icons: {
        type: Array,
        default: () => [],
      },
      itemList: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      selected: {
        items: [],
      },
    }),
    methods: {
      gaEvent(label) {
        if (label.includes('General Support')) {
          this.trackEvent('trigger_general_support_link_clicked')
        } else if (label.includes('Test Your Speed')) {
          this.trackEvent('EQ_START_SPEED_TEST')
        } else if (label.includes('Order Remote')) {
          this.trackEvent('trigger_order_remote_clicked')
        } else if (label.includes('Equipment Return')) {
          this.trackEvent('trigger_equipment_return_clicked')
        }
      },
      getIcon(icon) {
        return (
          {
            EquipmentIcon: EquipmentIcon,
            InternetIcon: InternetIcon,
            RemoteIcon: RemoteIcon,
            ServiceIcon: ServiceIcon,
            SpeedIcon: SpeedIcon,
            SupportIcon: SupportIcon,
            UpgradeModemIcon: UpgradeModemIcon,
          }[icon] ?? {}
        )
      },
    },
  }
</script>
<style scoped>
  .link {
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.07px;
    text-align: center;
    color: #0057b8;
  }
</style>
