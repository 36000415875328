<template>
  <main>
    <AppPanel
      label="Equipment"
      variant="transparent"
      spacing="none"
      padding="none"
      alignment="center"
      class="lg:hidden"
    ></AppPanel>

    <AppPanel label="My Equipment">
      <UpgradeYourModem />
      <EquipmentHelp
        :item-list="$store.getters['equipments/getEquipmentHelp']('Equipment')"
      ></EquipmentHelp>

      <AppSuspense :dependencies="['equipments']">
        <template #loading>
          <AppPanel variant="alternate">
            <p class="animate-pulse text-center">Loading equipment...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-center text-error">Unable to load. Please try again later.</p>
          </AppPanel>
        </template>
        <template #ready>
          <EquipmentDetails
            v-bind="$store.state.equipments"
            @reboot="$store.dispatch('equipments/rebootEquipment', $event)"
          ></EquipmentDetails>
        </template>
      </AppSuspense>

      <AppSuspense :dependencies="['equipments']">
        <template #loading>
          <AppPanel
            label="Modem"
            variant="alternate"
          >
            <p class="animate-pulse text-center">Loading equipments...</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel
            label="Modem"
            variant="alternate"
          >
            <p class="text-center text-error">Unable to load equipments. Please try again later.</p>
          </AppPanel>
        </template>
        <template #ready>
          <EquipmentStatus
            v-bind="$store.state.equipments"
            @reboot="$store.dispatch('equipments/rebootEquipment', $event)"
          ></EquipmentStatus>
        </template>
      </AppSuspense>

      <NeedHelp></NeedHelp>
    </AppPanel>
  </main>
</template>

<script>
  import EquipmentDetails from '../../components/EquipmentDetails/index.vue'
  import EquipmentHelp from '../../components/EquipmentHelp/index.vue'
  import EquipmentStatus from '../../components/EquipmentStatus/index.vue'
  import NeedHelp from '../../components/NeedHelp/index.vue'
  import UpgradeYourModem from '../../components/UpgradeYourModem/index.vue'

  export default {
    name: 'EquipmentPage',
    components: {
      EquipmentDetails,
      EquipmentHelp,
      EquipmentStatus,
      NeedHelp,
      UpgradeYourModem,
    },
  }
</script>
