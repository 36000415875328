<template>
  <AppPanel
    variant="transparent"
    padding="none"
    spacing="none"
    class="flex items-center justify-between gap-4"
  >
    <div>
      <div class="py-2 text-2xl font-bold leading-tight lg:text-3xl">Welcome, {{ loginName }}!</div>
      <div
        v-if="displayAccountNumber"
        class="font-book"
      >
        Account #: {{ accountNumber }}
      </div>
    </div>
    <div class="m-0 lg:hidden">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
      >
        <path
          d="M17.7278 19.1019C11.179 21.0719 5.89689 26.5508 5.89062 34.0337L17.6989 34.0438L17.7278 19.1019Z"
          fill="#0057B8"
        ></path>
        <path
          d="M17.7284 19.1019C17.5605 25.9957 21.0911 32.7592 28.141 35.0769L31.7994 23.7462L17.7284 19.1019Z"
          fill="#00A8EC"
        ></path>
        <path
          d="M17.7285 19.1019C24.1733 21.3931 31.6379 20.0945 36.0004 14.0441L26.4523 7.03149L17.7285 19.1019Z"
          fill="#0057B8"
        ></path>
        <path
          d="M17.7287 19.102C21.8795 13.6231 22.962 6.05795 18.6082 0L9.04883 6.99743L17.7287 19.102Z"
          fill="#00A8EC"
        ></path>
        <path
          d="M17.7281 19.1019C13.848 13.4246 7.05367 10.0472 0 12.3548L3.63959 23.6919L17.7281 19.1019Z"
          fill="#004282"
        ></path>
      </svg>
    </div>
  </AppPanel>
</template>

<script>
  export default {
    name: 'WelcomeUser',
    props: {
      loginName: {
        type: String,
        default: 'Customer',
      },
      accountNumber: {
        type: String,
        required: true,
      },
      displayAccountNumber: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
