<template>
  <div>
    <AppPanel
      label="Breezeline Apps"
      spacing="none"
    >
      <div class="grid gap-y-2 rounded-md bg-gray-light p-4 shadow lg:grid-cols-2 lg:gap-y-4">
        <div
          v-for="callout in callouts"
          :key="callout.title"
          class="callout flex flex-col gap-4 border-b border-gray last:border-b-0"
        >
          <div class="mb-6 mt-4 flex flex-col justify-between gap-4 lg:mb-8 lg:gap-8">
            <div class="flex flex-col-reverse justify-end gap-4 lg:flex-row-reverse">
              <div class="flex flex-col gap-2 text-left text-sm">
                <div class="font-bold leading-tight">
                  {{ callout.title }}
                </div>
                <div>
                  {{ callout.description }}
                </div>
                <div class="hidden lg:block">
                  <BzlButton
                    variant="text"
                    class="underline"
                    @click="toggleModal(callout)"
                  >
                    Show QR Code
                  </BzlButton>
                </div>
              </div>
              <img
                :src="callout.img"
                alt=""
                class="h-28 w-28 lg:block lg:h-40 lg:w-40"
              />
            </div>
            <div>
              <div class="flex items-center gap-4">
                <a
                  class="hidden lg:block"
                  :href="callout.appStore"
                  target="_blank"
                  @click="trackEvent('AD_' + callout.appName)"
                >
                  <img
                    src="./app-store-logo.svg"
                    alt="Download app on the Apple App Store"
                    class="w-28 lg:w-32"
                  />
                </a>
                <a
                  class="hidden lg:block"
                  :href="callout.googlePlay"
                  target="_blank"
                  @click="trackEvent('AD_' + callout.appName)"
                >
                  <img
                    src="./google-play-logo.png"
                    alt="Download app on Google Play"
                    class="w-28 lg:w-32"
                  />
                </a>
                <a
                  v-if="isIOS()"
                  class="lg:hidden"
                  :href="callout.appStore"
                  target="_blank"
                >
                  <img
                    src="./app-store-logo.svg"
                    alt="Download app on the Apple App Store"
                    class="w-28 lg:w-32"
                  />
                </a>
                <a
                  v-else-if="isAndroid()"
                  class="lg:hidden"
                  :href="callout.googlePlay"
                  target="_blank"
                >
                  <img
                    src="./google-play-logo.png"
                    alt="Download app on Google Play"
                    class="w-28 lg:w-32"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BzlModal
        :open="modals.qrCodeModal"
        label="How to scan QR codes"
        @sl-after-hide="modals.qrCodeModal = false"
      >
        <hr class="mb-8 border-gray-light" />
        <div class="flex flex-col gap-4">
          <div class="flex flex-row justify-around text-center text-black">
            <img
              :src="modals.selectedModal"
              alt="QR code"
              class="h-36 w-36"
            />
          </div>
          <div>
            <hr class="border-gray-light pt-4 lg:mt-8" />
            <div class="font-bold underline">Directions</div>
            <ol class="list-decimal pl-4 pt-4">
              <li>Open the camera app.</li>
              <li>Focus the camera on the QR code by gently tapping the code.</li>
              <li>Follow the instructions on the screen to complete the action.</li>
            </ol>
          </div>
        </div>
      </BzlModal>
    </AppPanel>
  </div>
</template>

<script>
  import MyBzlAppIcon from './my-breezeline-app-icon.png'
  import WifiYourWayIcon from './wifi-your-way-app-icon.png'
  import TvAppIcon from './tv-app-icon.png'
  import AppIcon from './breezeline-app-icon.png'
  import MyAccountQr from './qr.png'
  import WifiYourWayQr from './wifi-your-way-home-app.png'
  import TivoAppQr from './tivo-app.png'
  import StreamTvAppQr from './stream-tv-app.png'

  export default {
    name: 'MarketingCallouts',

    data: () => ({
      modals: {
        selectedModal: [],
        qrCodeModal: false,
      },
      callouts: [
        {
          title: 'Take control with the My Breezeline App',
          appName: 'MY_BREEZELINE',
          description:
            'Access your Breezeline account from practically anywhere with the new My Breezeline Mobile App.',
          img: MyBzlAppIcon,
          modalQRCode: MyAccountQr,
          appStore: 'https://apps.apple.com/us/app/my-breezeline/id1568553022',
          googlePlay: 'https://play.google.com/store/apps/details?id=com.breezeline&hl=en_US&gl=US',
        },
        {
          title: 'Manage your network with the WiFi Your Way™ Home App.',
          appName: 'WIFI_YOUR_WAY',
          description:
            'Maximize your WiFi experience with expanded reach, added security, and more control.',
          img: WifiYourWayIcon,
          modalQRCode: WifiYourWayQr,
          appStore: 'https://apps.apple.com/us/app/wifi-your-way/id1534018874',
          googlePlay:
            'https://play.google.com/store/apps/details?id=com.plumewifi.plume.abb&hl=en_US&gl=US',
        },
        {
          title: 'Watch shows on the go with the Breezeline TV (Tivo App)',
          appName: 'BREEZELINE_TV',
          description:
            'Enjoy your favorite shows and movies from practically anywhere. Just grab your smartphone, tablet or laptop and kick back.',
          img: TvAppIcon,
          modalQRCode: TivoAppQr,
          appStore: 'https://apps.apple.com/us/app/breezeline-tv/id787428890',
          googlePlay:
            'https://play.google.com/store/apps/details?id=com.tivo.android.abb&hl=en_US&gl=US',
        },
        {
          title: 'Watch shows on the go with the Breezeline Stream TV App.',
          appName: 'STREAM_TV',
          description:
            'Enjoy your favorite shows and movies from practically anywhere. Just grab your smartphone, tablet or laptop and kick back.',
          img: AppIcon,
          modalQRCode: StreamTvAppQr,
          appStore: 'https://apps.apple.com/us/app/breezeline-stream-tv/id1582018156',
          googlePlay:
            'https://play.google.com/store/apps/details?id=com.breezeline.streamtv&hl=en_US&gl=US',
        },
      ],
    }),
    methods: {
      toggleModal(callout) {
        this.modals.selectedModal = callout.modalQRCode
        this.modals.qrCodeModal = true
      },
    },
  }
</script>

<style>
  .BzlButton[variant='text']::part(label) {
    @apply p-0;
  }
  .callout:nth-last-child(2) {
    @apply lg:border-0;
  }
</style>
