<template>
  <BzlModal
    label="Enroll in AutoPay"
    @sl-after-hide="$emit('dismiss')"
  >
    <div class="space-y-4">
      <hr />
      <div>Payment Method</div>
      <div
        v-if="paymentMethods.length > 0"
        class="space-y-2"
      >
        <AppRadioPanel
          v-for="pm in paymentMethods"
          :key="pm.id"
          :is-payment-method="true"
          :payment-method="pm"
          :is-selected="pm === selectedPaymentMethod"
          @click.native="select(pm)"
        ></AppRadioPanel>
      </div>
      <div>
        <p>
          AutoPay will take effect on your next statement. Your full balance due will be paid
          automatically each month using the above payment method.
        </p>
      </div>
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="$emit('dismiss')"
        >
          Cancel
        </BzlButton>
        <BzlButton
          variant="primary"
          :disabled="selectedPaymentMethod === null"
          @click="confirm()"
        >
          Enable AutoPay
        </BzlButton>
      </div>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'SetAsAutopay',
    props: {
      paymentMethods: {
        type: Array,
        default: () => [],
      },
      preselectedPaymentMethod: {
        type: Object,
        default: null,
        required: false,
      },
    },
    data: () => ({
      $selectedPaymentMethod: null,
    }),
    computed: {
      selectedPaymentMethod: {
        get() {
          if (this.preselectedPaymentMethod) {
            return this.preselectedPaymentMethod
          }

          return this.$selectedPaymentMethod ?? null
        },
        set(pm) {
          this.$emit('update:preselected-payment-method', pm)
          this.$selectedPaymentMethod = pm
        },
      },
    },
    mounted() {
      // this.trackEvent('trigger_enable_autopay_clicked')
    },
    methods: {
      select(pm) {
        this.selectedPaymentMethod = pm
      },
      confirm() {
        this.$emit('confirm', this.selectedPaymentMethod, () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
