<template>
  <sl-dialog
    class="BzlModal"
    v-bind="{ ...$props, ...$attrs }"
    :no-header="noHeader"
    :label="label"
    v-on="$listeners"
  >
    <slot></slot>
    <slot name="inner-modal"></slot>
    <slot name="footer"></slot>
  </sl-dialog>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'

  export default {
    name: 'BzlModal',
    props: {
      open: {
        type: Boolean,
        required: false,
        default: false,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      noHeader: {
        // proxy for the native 'no-header' prop
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style>
  .BzlModal::part(base) {
    @apply z-50 max-md:w-full max-md:max-w-full;
  }

  .BzlModal::part(panel) {
    @apply p-5 max-md:mx-4 max-md:w-full max-md:max-w-full lg:p-8;
  }

  .BzlModal::part(header) {
    @apply relative;
  }

  .BzlModal::part(header),
  .BzlModal::part(body),
  .BzlModal::part(title),
  .BzlModal::part(close-button__base),
  .BzlModal::part(close-button) {
    @apply p-0 text-navy-dark;
  }

  .BzlModal::part(close-button) {
    @apply absolute -right-2 -top-2;
  }

  .BzlModal::part(close-button__base) {
    @apply text-5xl text-blue;
  }

  .BzlModal::part(title) {
    @apply mt-10 pb-4 text-xl font-bold lg:mt-4 lg:pr-4 lg:text-2xl;
  }
</style>
