<template>
  <Step heading="Step 2: Confirm payment details">
    <p>
      You are scheduling a payment of <strong>${{ paymentAmount }}</strong> to be submitted on
      <strong>{{ toFriendlyDate(paymentDate, 'full-truncated') }}</strong> using
      <strong>
        {{ paymentMethod.accountHolder }}'s {{ paymentMethod.displayName }} ({{
          paymentMethod.lastFour || paymentMethod.accountNumber
        }})
      </strong>
    </p>
    <hr />
    <template #footer>
      <div class="flex w-full items-center justify-between">
        <BzlButton
          variant="text"
          @click="$emit('go-back')"
        >
          Back
        </BzlButton>
        <BzlButton
          variant="primary"
          @click="$emit('approve')"
        >
          Confirm
        </BzlButton>
      </div>
    </template>
  </Step>
</template>

<script>
  import Step from './step.vue'

  export default {
    name: 'PaymentWizardStep2',
    components: {
      Step,
    },
    props: {
      paymentAmount: {
        type: String,
        required: true,
        validator: (val) => val > 0,
      },
      paymentDate: {
        type: String,
        required: true,
      },
      paymentMethod: {
        type: Object,
        required: true,
      },
      isFuture: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
