import gql from 'graphql-tag'
import { apolloProvider } from './plugins/vue-apollo.js'

const client = apolloProvider.defaultClient

async function login(authType, callbacks) {
  if (authType === null) {
    return {
      user: null,
      apolloProvider,
    }
  }

  // as long as authType is not null, we always ping to get a x-csrf-token
  try {
    // get csrf token, store in headers (see apollo config)
    await client.query({
      query: gql`
        query pingSync {
          pingSync
        }
      `,
    })
  } catch (e) {
    window.Sentry.captureException(e)
    callbacks.toServerErrorPage()
  }

  if (authType === '2fa') {
    const response = await is2FAuthorized(callbacks)

    if (response.redirectUri) {
      window.location.href = response.redirectUri
      return callbacks.toLoadingPage()
    }

    return {
      user: response,
      apolloProvider,
    }
  }

  if (authType === 'user') {
    let response = await isLoggedIn(callbacks)

    if (response.status) {
      if(typeof window.gtag !== 'undefined'){
        window.gtag('event', 'trigger_user_signed_in')
      }
      setInterval(async () => {
        // start polling for session validity
        response = await isLoggedIn(callbacks)
        if (!response.status) {
          await redirectToExternalAuth(callbacks)
        }
      }, 1000 * import.meta.env.VITE_AUTH_SESSION_POLL_RATE)

      return {
        user: response.user,
        apolloProvider,
      }
    } else {
      //Make sure window exists before running evenet
      if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'trigger_error_login_error')
      }
      await redirectToExternalAuth(callbacks)
    }
  }
}

async function is2FAuthorized(callbacks) {
  let response = null

  try {
    response = await client.query({
      query: gql`
        query ($easypayUri: String!) {
          getNonauthAccountInfo(easypayUri: $easypayUri) {
            accountLastFour
            amountDelinquentStv
            daysDelinquent
            amountDueStv
            dueDate
            redirectUri
            pendingDisconnect
            serviceAddress {
              addressLine1
              addressLine2
              city
              postalCode
              state
            }
          }
        }
      `,
      variables: {
        easypayUri: window.location.href,
      },
    })

    if (
      !response.data.getNonauthAccountInfo.accountLastFour &&
      !response.data.getNonauthAccountInfo.redirectUri
    ) {
      throw new Error('No redirect uri was provided')
    }
  } catch (e) {
    // window.Sentry.captureException(e)
    callbacks.toServerErrorPage()
    return false
  }

  return response.data.getNonauthAccountInfo
}

async function isLoggedIn(callbacks) {
  let response = null

  try {
    response = await client.query({
      query: gql`
        query loginStatus {
          loginStatus {
            email
            name
            username
          }
        }
      `,
    })
  } catch (e) {
    if (window.Sentry) {
      window.Sentry.captureException(e)
    }

    callbacks.toServerErrorPage()
  }

  return {
    status: isValidUser(response.data.loginStatus),
    user: response.data.loginStatus,
  }
}

function isValidUser(user) {
  if (user === null || Object.keys(user).length === 0) return false

  // note: some user might not have an email?
  return !(user.name === null || user.email === null || user.username === null)
}

async function redirectToExternalAuth(callbacks) {
  let response = null

  try {
    response = await client.mutate({
      mutation: gql`
        mutation {
          authUrl
        }
      `,
    })
  } catch (e) {
    window.Sentry.captureException(e)
    callbacks.toServerErrorPage()
  }

  if (response.data?.authUrl) {
    window.location = response.data.authUrl
  } else {
    callbacks.toCookieAccessErrorPage()
  }
}

export { login }
