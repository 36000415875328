<template>
  <div>
    <AppPanel
      label="Payment Methods"
      :open="true"
      :collapsible="true"
    >
      <div
        v-if="paymentMethods.length > 0"
        class="grid grid-cols-1 gap-4"
        :class="paymentMethods.length > 1 ? 'lg:grid-cols-2' : null"
      >
        <AppPanel
          v-for="pm in paymentMethods"
          :key="pm.id"
          padding="tight"
          variant="primary"
        >
          <div
            v-if="pm.paymentType === 'CARD'"
            class="text-sm leading-relaxed"
          >
            <div class="whitespace-nowrap font-bold">
              {{ pm.displayName }}
            </div>

            <div class="mb-2">
              {{ pm.accountHolder }}<br />
              Card # {{ pm.lastFour }}<br />
              Expires {{ pm.expiresOn }}
            </div>
          </div>

          <div v-if="pm.paymentType === 'EFT'">
            <div class="whitespace-nowrap text-lg font-bold">
              <i class="fas fa-landmark text-salmon"></i>
              {{ pm.displayName }}
            </div>

            <div class="mb-2">
              {{ pm.accountHolder }}<br />
              Acct # {{ pm.accountNumber }}<br />
              Routing # {{ pm.routingNumber }}
            </div>
          </div>

          <div class="flex flex-wrap gap-2 text-sm lg:gap-x-4">
            <div v-if="isExpired(pm)">
              <sl-tooltip
                content="Expired"
                placement="top"
              >
                <BzlSwitch :disabled="true"></BzlSwitch>
                <span class="font-bold">Set Default</span>
              </sl-tooltip>
            </div>
            <div v-else-if="pm.isDefault">
              <BzlSwitch
                checked
                :disabled="numberOfPaymentMethods === 1"
              ></BzlSwitch>
              <span class="font-bold">Set Default</span>
            </div>
            <div v-else>
              <BzlSwitch @click.prevent="$emit('set-as-default', pm)"></BzlSwitch>
              <span class="font-bold">Set Default</span>
            </div>

            <div v-if="pm.paymentType === 'CARD' && isExpired(pm)">
              <sl-tooltip
                content="Expired"
                placement="top"
              >
                <BzlSwitch :disabled="true"></BzlSwitch>
                <span class="font-bold">Set AutoPay</span>
              </sl-tooltip>
            </div>
            <div v-else-if="isUsedForAutopay(pm)">
              <BzlSwitch
                checked
                @click.prevent="$emit('disable-autopay')"
              ></BzlSwitch>
              <span class="font-bold"> Set AutoPay </span>
            </div>
            <div v-else>
              <BzlSwitch
                @click.prevent="$emit('set-as-autopay', pm)"
                :disabled="!isAutopayAvailable"
              ></BzlSwitch>
              <span class="font-bold">Set AutoPay</span>
            </div>

            <div>
              <a
                href="#"
                class="flex cursor-pointer items-center gap-2"
                @click.prevent="deletePaymentMethod(pm)"
              >
                <BzlIcon
                  name="x-circle-fill"
                  class="text-salmon"
                ></BzlIcon>
                <span class="font-bold">Delete</span>
              </a>
            </div>
          </div>
        </AppPanel>
      </div>

      <template v-else>
        <p class="text-center">No payment methods found.</p>
      </template>

      <div class="flex w-full justify-center lg:col-span-2">
        <BzlButton
          v-if="paymentMethods.length < maxPaymentMethods"
          variant="primary"
          :mFull="true"
          @click="addPaymentMethod()"
        >
          <i class="fas fa-plus"></i>
          Add payment method
        </BzlButton>
      </div>
    </AppPanel>
    <slot></slot>
  </div>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js'

  export default {
    name: 'PaymentMethods',
    props: {
      paymentMethods: {
        type: Array,
        default: () => [],
      },
      maxPaymentMethods: {
        type: Number,
        default: 4,
      },
      autopayPaymentMethod: {
        type: Object,
        required: false,
        default: null,
      },
      isAutopayAvailable: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    computed: {
      numberOfPaymentMethods() {
        return this.paymentMethods.length
      },
    },
    methods: {
      addPaymentMethod() {
        this.trackEvent('trigger_manage_payment_methods_clicked')
        this.$emit('add')
      },
      deletePaymentMethod(pm) {
        this.trackEvent('trigger_delete_payment_method_clicked')
        this.$emit('delete', pm)
      },
      isUsedForAutopay(pm) {
        return this.autopayPaymentMethod && this.autopayPaymentMethod.id === pm.id
      },
      isExpired(pm) {
        if (pm.paymentType === 'EFT') {
          return false
        }

        return this.isCCExpired(pm.expiresOn)
      },
    },
  }
</script>
