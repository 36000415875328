<template>
  <AppPanel class="bg-teal">
    <div class="text-center text-white">
      <strong class="text-lg">
        <i class="fas fa-money-bill-wave"></i>
        Save time and money </strong
      ><br />
      when you enable automatic payments and paperless billing!
    </div>
    <div class="flex flex-row justify-center">
      <BzlButton
        variant="default"
        @click="$emit('enroll-now')"
      >
        Enroll now!
      </BzlButton>
    </div>
  </AppPanel>
</template>

<script>
  /* Note: This is a standalone banner in Dashboard.
             This is not the banner that's inside the Discounts panel in Billing. */
  export default {
    name: 'DiscountBanner',
  }
</script>
