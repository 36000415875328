<template>
  <sl-alert
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    class="BzlAlert"
  >
    <sl-icon
      slot="icon"
      :name="iconName"
    ></sl-icon>
    <slot></slot>
  </sl-alert>
</template>

<script>
  // https://shoelace.style/components/alert
  import '@shoelace-style/shoelace/dist/components/alert/alert.js'

  import { nextTick } from 'vue'

  export default {
    name: 'BzlAlert',
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      closable: {
        type: Boolean,
        default: false,
      },
      variant: {
        type: String,
        default: 'primary',
        validator: (val) =>
          ['primary', 'success', 'neutral', 'warning', 'danger', 'banner'].includes(val),
      },
      duration: {
        type: String,
        default: 'Infinity',
      },
    },
    computed: {
      iconName() {
        return {
          primary: 'info-circle',
          success: 'check2-circle',
          neutral: 'gear',
          warning: 'exclamation-triangle',
          danger: 'exclamation-octagon',
        }[this.variant]
      },
    },
  }
</script>

<style>
  .BzlAlert[variant='banner']::part(base) {
    @apply rounded-md border-t-0 bg-blue text-white;
  }

  .BzlAlert[variant='banner']::part(close-button__base) {
    @apply absolute right-0 top-0 text-white;
  }

  .BzlAlert[variant='warning']::part(base) {
    @apply rounded-none border-0 bg-navy text-white;
  }

  .BzlAlert[variant='warning']::part(icon) {
    @apply p-4 text-white lg:p-8;
    background-color: #d40000;
  }

  .BzlAlert[variant='warning']::part(close-button__base) {
    @apply absolute right-0 top-0 text-white;
  }
  .BzlAlert[variant='primary']::part(base) {
    @apply rounded-none border-0 bg-navy text-white;
  }

  .BzlAlert[variant='primary']::part(icon) {
    @apply p-4 text-white lg:p-8;
    background-color: #1a68c0;
  }

  .BzlAlert[variant='primary']::part(close-button__base) {
    @apply absolute right-0 top-0 text-white;
  }

  .BzlAlert::part(close-button) {
    @apply p-0;
  }

  .BzlAlert[variant='banner']::part(icon) {
    @apply hidden;
  }

  .BzlAlert[variant='banner']::part(message) {
    @apply p-6 lg:p-8;
  }

  .BzlAlert::part(close-button__base) {
    @apply text-4xl;
  }
</style>
