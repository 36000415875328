<template>
  <DiscountItem
    discount-name="AutoPay"
    :available="available"
    :enabled="enabled"
    v-on="$listeners"
  >
    <template #button-enabled>
      <div>
        <span class="text-sm font-bold">AutoPay On</span>
      </div>
    </template>
    <template #button-disabled>
      <div>
        <span class="text-sm font-bold">AutoPay Off</span>
      </div>
    </template>
    <template #button-unavailable>
      <span class="text-sm font-bold">AutoPay Unavailable</span>
    </template>
    <template #description>
      Avoid the hassle of missing payments and save time by scheduling automatic payments.
    </template>
    <template #status-enabled>
      <span class="text-sm">Your monthly statements will be sent to</span>
      <span>
        {{ paymentMethod.accountHolder }}'s
        {{ paymentMethod.displayName }}
        ({{ paymentMethod.lastFour || paymentMethod.accountNumber }})
      </span>
    </template>
    <template #status-disabled>
      You are not enrolled in AutoPay. You will need to manually set up payments before each due
      date.
    </template>
    <template #status-unavailable>
      AutoPay is not available in your location. Please contact Support for more information.
    </template>
  </DiscountItem>
</template>

<script>
  import DiscountItem from '../Discounts/item.vue'

  export default {
    name: 'Autopay',
    components: {
      DiscountItem,
    },
    props: {
      available: {
        type: Boolean,
        default: false,
      },
      paymentMethod: {
        type: [Object, null],
        default: null,
      },
    },
    computed: {
      enabled() {
        return this.paymentMethod !== null
      },
    },
  }
</script>
