<template>
  <CSGWidget
    v-bind="$attrs"
    :create-profile="true"
    v-on="$listeners"
  ></CSGWidget>
</template>

<script>
  import CSGWidget from '../CSGWidget/index.vue'

  export default {
    name: 'AddPaymentMethod',
    components: {
      CSGWidget,
    },
  }
</script>
