import gql from 'graphql-tag'

export default {
  namespaced: true,

  state: {
    mobileInfo: {
      mobileAvailable: null,
      mobileDiscountQualified: null,
      mobileEligible: null,
      mobileSummary: null,
    },
  },

  getters: {},
  mutations: {
    SET_MOBILE_INFO({ mobileInfo }, payload) {
      mobileInfo = Object.assign(mobileInfo, payload)
      delete mobileInfo.__typename
    },
  },
  actions: {
    initialize({ dispatch }) {
      return Promise.all([dispatch('_getMobileEligibility')])
        .then((all) => {
          dispatch('moduleIsReady', { name: 'mobile', result: true }, { root: true })
          return all
        })
        .catch((e) => {
          dispatch(
            'moduleIsReady',
            { name: 'mobile', result: { error: e } },
            { root: { error: e } }
          )
        })
    },
    _getMobileEligibility({ commit }) {
      return new Promise((resolve, reject) => {
        window.$apollo.addSmartQuery('getMobileEligibility', {
          query: gql`
            query getMobileEligibility {
              getMobileEligibility {
                mobileAvailable
                mobileDiscountQualified
                mobileEligible
                mobileSummary
              }
            }
          `,
          result: (r) => {
            r.data?.getMobileEligibility &&
              resolve(commit('SET_MOBILE_INFO', r.data.getMobileEligibility))
          },
          error: (e) => reject(e),
        })
      }).catch((e) => {
        throw e?.message?.includes('server error') ? 'mobile server error' : `mobile error: ${e}`
      })
    },
  },
}
