<template>
  <main>
    <AppPanel
      label="Support"
      variant="transparent"
      spacing="none"
      padding="none"
      alignment="center"
      class="lg:hidden"
    ></AppPanel>

    <SupportCenter
      :quickActions="true"
      :search="true"
    ></SupportCenter>

    <AppSuspense :dependencies="['support']">
      <template #loading>
        <AppPanel variant="alternate">
          <p class="animate-pulse text-center">Loading support articles...</p>
        </AppPanel>
      </template>
      <template #error>
        <AppPanel variant="alternate">
          <p class="text-center text-error">
            Unable to load support articles. Please try again later.
          </p>
        </AppPanel>
      </template>
      <template #ready>
        <SupportCategories
          :articles-by-category="$store.getters['support/getArticleByCategory']"
          :articles-by-sub-category="$store.getters['support/getArticleBySubCategory']"
          :articles-by-title="$store.getters['support/getArticleByTitle']"
        ></SupportCategories>
      </template>
    </AppSuspense>

    <MarketingCallouts></MarketingCallouts>
    <SupportCenter
      :questions="true"
      label="More Questions?"
      :open-chat="openChat"
    ></SupportCenter>
  </main>
</template>

<script>
  import SupportCenter from '../../components/SupportCenter/index.vue'
  import MarketingCallouts from '../../components/MarketingCallouts/index.vue'
  import SupportCategories from '../../components/SupportCategories/index.vue'
  import { addClickToSupport } from '../../ccai/ccai'

  export default {
    name: 'ServicePage',
    components: {
      SupportCenter,
      MarketingCallouts,
      SupportCategories,
    },
    mounted() {
      if (this.$store.state.support.articles.length === 0) {
        this.$store.dispatch('support/initialize')
      }
      //whenever this component is mounted, add the click even hander to "chat with us"
      addClickToSupport()
    },
    methods: {
      openChat() {
        window._genesys.widgets.openWebChat(window._genesys.widgets.customPlugin)
      },
    },
  }
</script>
