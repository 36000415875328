<template>
  <StatementHistory
    :statements="$store.state.statements.allStatements"
    @download-statement="$store.dispatch('statements/downloadStatement', $event)"
    @view-bill-explainer="$store.dispatch('statements/getBillExplainerUri')"
  ></StatementHistory>
</template>

<script>
  import StatementHistory from '../../components/StatementHistory/index.vue'

  export default {
    name: 'StatementHistorySection',
    components: {
      StatementHistory,
    },
  }
</script>
