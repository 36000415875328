<template>
  <MakeAPayment
    :amount-due="$store.state.billing.amountDue"
    :is-promise-to-pay-eligible="$store.getters['billing/isPromiseToPayEligible']"
    :has-promise-to-pay-scheduled="$store.getters['billing/hasPromiseToPayScheduled']"
    :promise-to-pay-date="$store.state.billing.promiseToPayPayments?.date"
    :promise-to-pay-amount="$store.getters['billing/getPromiseToPayAmount']"
    :days-past-due="$store.state.billing.daysPastDue"
    :is-past-due="$store.getters['billing/accountIsPastDue']"
    :due-date="$store.state.billing.dueDate"
    :is-on-autopay="$store.getters['discounts/autopayIsEnabled']"
    :had-autopay="$store.getters['billing/accountHadAutopay']"
    :autopay-draft-date="$store.state.billing.debitDate"
    :expiry-notice="$store.getters['paymentMethods/getExpiringCreditCardNotice']"
    :available="$store.getters['discounts/discountsAreAvailable']"
    :enabled="$store.getters['discounts/discountsAreEnabled']"
    :payment-method="$store.state.discounts.autopay.paymentMethod"
    :show-disclaimer="true"
    :has-pending-payment="$store.getters['billing/getPendingPaymentTotalStv']"
    @view-bill-explainer="$store.dispatch('statements/getBillExplainerUri')"
    @pay-now="togglePaymentWizard()"
    @promise-to-pay="togglePromiseToPayWizard()"
    @review-promise-to-pay="togglePromiseToPayWizard('review')"
    @cancel-promise-to-pay="togglePromiseToPayWizard('cancel')"
    @on-disclaimer-click="modals.promiseToPayDisclaimer"
  >
    <PaymentWizard
      :open="modals.paymentWizard"
      :amount-due="$store.state.billing.amountDue"
      :due-date="$store.getters['billing/debitOrDueDate']"
      :payment-methods="$store.state.paymentMethods.allPaymentMethods"
      :max-payment-methods="maxPaymentMethods"
      :scheduled-payments="$store.getters['billing/getScheduledPayments']"
      :autopay-draft-date="$store.state.billing.debitDate"
      @submit-payment="submitPayment"
      @dismiss="closePaymentWizard()"
      @add-payment-method="addPaymentMethod"
      @make-one-time-payment="showOneTimePayment($event)"
    ></PaymentWizard>

    <OneTimePayment
      :show-modal="modals.oneTimePayment"
      :session="$store.state.paymentWidget.session"
      :uuid="generateUUID()"
      :payment-amount="otpPaymentAmount"
      @error="onWidgetError($event)"
      @dismiss="hideOneTimePayment()"
    ></OneTimePayment>

    <PromiseToPayWizard
      v-if="modals.promiseToPayWizard && $store.getters['billing/isPromiseToPayEligible']"
      :open="modals.promiseToPayWizard"
      :window-type="promiseToPayWindowType"
      :amount-due="$store.state.billing.amountDue"
      :past-due="$store.state.billing.pastDue"
      :days-past-due="$store.state.billing.daysPastDue"
      :due-date="$store.getters['billing/debitOrDueDate']"
      :is-promise-to-pay-eligible="$store.getters['billing/isPromiseToPayEligible']"
      :has-promise-to-pay-scheduled="$store.getters['billing/hasPromiseToPayScheduled']"
      :promise-to-pay-date="$store.state.billing.promiseToPayPayments?.date"
      :promise-to-pay-amount="$store.getters['billing/getPromiseToPayAmount']"
      :payment-methods="$store.state.paymentMethods.allPaymentMethods"
      :max-payment-methods="maxPaymentMethods"
      :scheduled-payments="$store.getters['billing/getScheduledPayments']"
      :scheduled-promise-to-pay="$store.getters['billing/getPromiseToPay']"
      @enable="modals.promiseToPayWizard && $store.getters['billing/isPromiseToPayEligible']"
      @submit-promise-to-pay="submitPromiseToPay"
      @convert-to-promise-to-pay="convertToPromiseToPay"
      @dismiss="closePromiseToPayWizard()"
      @modify-promise-to-pay="updatePromiseToPayWindowType('modify')"
      @show-cancel-promise-to-pay="updatePromiseToPayWindowType('cancel')"
      @add-payment-method="addPaymentMethod"
      @cancel-promise-to-pay="cancelPromiseToPay"
    ></PromiseToPayWizard>

    <div class="flex flex-col gap-4 lg:flex-row">
      <Autopay
        :available="$store.state.discounts.autopay.isAvailable"
        :payment-method="$store.state.discounts.autopay.paymentMethod"
        @enable="modals.enableAutopay = true"
        @disable="modals.disableAutopay = true"
        class="w-full"
      ></Autopay>
      <Ecosave
        :enabled="$store.state.discounts.ecosave.isEnabled"
        :email="$store.state.account.customerInfo.emailAddress"
        @enable="showEnableEcosave()"
        @disable="modals.disableEcosave = true"
        class="w-full"
      ></Ecosave>
    </div>

    <template #modals>
      <EnableAutopay
        :open="modals.enableAutopay"
        :payment-methods="$store.state.paymentMethods.allPaymentMethods"
        @dismiss="modals.enableAutopay = false"
        @confirm="enableAutopay"
      ></EnableAutopay>

      <DisableAutopay
        :open="modals.disableAutopay || disableAutopayOverride"
        :will-lose-discount="$store.getters['discounts/discountsAreEnabled']"
        @dismiss="modals.disableAutopay = false"
        @confirm="disableAutopay"
      ></DisableAutopay>

      <EnableEcosave
        :open="modals.enableEcosave"
        :email="$store.state.account.customerInfo.emailAddress"
        @dismiss="modals.enableEcosave = false"
        @confirm="enableEcosave"
        @change-email="$router.push({ name: 'account' })"
      ></EnableEcosave>

      <DisableEcosave
        :open="modals.disableEcosave"
        :will-lose-discount="$store.getters['discounts/discountsAreEnabled']"
        @dismiss="modals.disableEcosave = false"
        @confirm="disableEcosave"
      ></DisableEcosave>

      <BzlModal
        :open="modals.promiseToPayDisclaimer"
        label="Promise to Pay Disclaimer"
        @sl-after-hide="modals.promiseToPayDisclaimer = false"
      >
        <div class="space-y-4">
          <p>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
            voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint
            occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt
            mollitia animi, id est laborum et dolorum fuga.
          </p>
          <div class="flex w-full flex-row justify-around">
            <BzlButton @click="modals.promiseToPayDisclaimer = false"> Cancel </BzlButton>
          </div>
        </div>
      </BzlModal>
    </template>
  </MakeAPayment>
</template>

<script>
  import MakeAPayment from '../../components/MakeAPayment/index.vue'
  import PaymentWizard from '../../components/PaymentWizard/index.vue'
  import OneTimePayment from '../../components/OneTimePayment/index.vue'
  import PromiseToPayWizard from '../../components/PromiseToPayWizard/index.vue'
  import Autopay from '../../components/Autopay/index.vue'
  import Ecosave from '../../components/Ecosave/index.vue'
  import EnableAutopay from '../../components/Autopay/enable.vue'
  import DisableAutopay from '../../components/Autopay/disable.vue'
  import EnableEcosave from '../../components/Ecosave/enable.vue'
  import DisableEcosave from '../../components/Ecosave/disable.vue'

  export default {
    name: 'MakeAPaymentSection',
    components: {
      PromiseToPayWizard,
      MakeAPayment,
      PaymentWizard,
      OneTimePayment,
      Autopay,
      Ecosave,
      EnableAutopay,
      DisableAutopay,
      EnableEcosave,
      DisableEcosave,
    },
    props: {
      disableAutopayOverride: {
        type: Boolean,
        required: false,
        default: false,
      },
      enableAutopayOverride: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      modals: {
        paymentWizard: false,
        oneTimePayment: false,
        promiseToPayWizard: false,
        enableAutopay: false,
        disableAutopay: false,
        enableEcosave: false,
        disableEcosave: false,
        promiseToPayDisclaimer: false,
      },
      highlightDiscounts: false,
      promiseToPayWindowType: '',
      otpPaymentAmount: 0,
      switches: {},
    }),
    computed: {
      maxPaymentMethods() {
        return Number(import.meta.env.VITE_MAX_PAYMENT_METHODS)
      },
      promiseToPayPayment() {
        return {}
      },
    },
    watch: {
      //Hide ujet widget whenever payment widget is opened
      'modals.paymentWizard'(){
        const myElement = document.getElementById("ujet");
        if(myElement.style.zIndex == 49){
          myElement.style.position = "static"
          myElement.style.zIndex = 50
        }else{
          myElement.style.zIndex = 49
          myElement.style.position = "absolute"
        }
        
      }
      ,
      switches(newVal) {
        if (newVal['show-payment-wizard']) {
          setTimeout(() => {
            this.modals.paymentWizard = true
            this.$store.commit('UNLOCK_APP')
          }, 200)

          this.$store.dispatch('setSwitch', {
            key: 'show-payment-wizard',
          })
        } else if (
          newVal['show-promise-to-pay-wizard'] ||
          newVal['modify-promise-to-pay-wizard'] ||
          newVal['cancel-promise-to-pay-wizard']
        ) {
          if (newVal['modify-promise-to-pay-wizard']) {
            this.$store.dispatch('setSwitch', {
              key: 'modify-promise-to-pay-wizard',
            })
            this.promiseToPayWindowType = 'modify'
          } else if (newVal['cancel-promise-to-pay-wizard']) {
            this.$store.dispatch('setSwitch', {
              key: 'cancel-promise-to-pay-wizard',
            })
            this.promiseToPayWindowType = 'cancel'
          }

          setTimeout(() => {
            this.modals.promiseToPayWizard = true
            this.$store.commit('UNLOCK_APP')
          }, 200)

          this.$store.dispatch('setSwitch', {
            key: 'show-promise-to-pay-wizard',
          })
        } else if (newVal['highlight-discounts']) {
          this.$store.commit('UNLOCK_APP')

          this.$refs.discounts.$el.scrollIntoView({
            alignToTop: true,
            behavior: 'smooth',
          })

          setTimeout(() => {
            this.highlightDiscounts = true

            setTimeout(() => {
              this.highlightDiscounts = false
            }, 1000)
          }, 300)

          this.$store.dispatch('setSwitch', {
            key: 'highlight-discounts',
          })
        }
      },
    },
    mounted() {
      this.switches = this.$store.state.switches

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })

      if (params.enable_autopay === 'true') {
        this.modals.enableAutopay = true
      }
    },
    methods: {
      togglePaymentWizard() {
        this.modals.paymentWizard = !this.modals.paymentWizard
      },
      togglePromiseToPayWizard(windowType = '') {
        this.promiseToPayWindowType = windowType
        this.modals.promiseToPayWizard = !this.modals.promiseToPayWizard
      },
      updatePromiseToPayWindowType(windowType = '') {
        this.promiseToPayWindowType = windowType
      },
      submitPayment(paymentDetails, callback) {
        return this.$store
          .dispatch('billing/submitPayment', paymentDetails)
          .then(callback)
          .then(() => this.$store.dispatch('billing/refetchAll'))
      },
      submitPromiseToPay(paymentDetails, callback) {
        return this.$store.dispatch('billing/submitPromiseToPay', paymentDetails).then(callback)
      },
      convertToPromiseToPay(paymentDetails, callback) {
        return this.$store.dispatch('billing/convertToPromiseToPay', paymentDetails).then(callback)
      },
      cancelPromiseToPay(callback) {
        return this.$store.dispatch('billing/cancelPromiseToPay').then(callback)
      },
      addPaymentMethod(payload) {
        this.$store.dispatch('setSwitchData', {
          returnTo: this.modals.paymentWizard
            ? 'show-payment-wizard'
            : 'show-promise-to-pay-wizard',
        })
        this.modals.paymentWizard = false
        this.modals.promiseToPayWizard = false

        this.$store.commit('SET_SWITCH', {
          key: 'add-payment-method',
          value: true,
        })
      },
      showOneTimePayment(paymentAmount) {
        this.modals.paymentWizard = false
        this.otpPaymentAmount = paymentAmount

        return this.$store
          .dispatch('paymentWidget/getWidgetSession')
          .then((r) => (this.modals.oneTimePayment = r && true))
      },
      hideOneTimePayment() {
        return this.$store.dispatch('billing/refetchAll').then(() => {
          this.otpPaymentAmount = 0
          this.modals.oneTimePayment = false
        })
      },
      onWidgetError(e) {
        console.log(e)
      },
      enableAutopay(paymentMethod, callback) {
        this.$store.dispatch('discounts/enableAutopay', paymentMethod).then(callback)
      },
      disableAutopay(callback) {
        this.modals.disableAutopay = false
        this.$emit('close-autopay')
        this.$store.dispatch('discounts/disableAutopay').then(callback)
      },
      showEnableEcosave() {
        const email = this.$store.state.account.customerInfo.emailAddress

        if (email && email.length) {
          this.modals.enableEcosave = true
        } else {
          alert(
            'No email address found. Please add a preferred email address in Account before enabling Ecosave'
          )
        }
      },
      enableEcosave(callback) {
        this.$store.dispatch('discounts/enableEcosave').then(callback)

        setTimeout(() => {
          this.modals.enableEcosave = false
        }, 300)
      },
      disableEcosave(callback) {
        this.$store.dispatch('discounts/disableEcosave').then(callback)
      },
      openPromiseToPayWizard(windowType = '') {
        this.promiseToPayWindowType = windowType
        this.modals.promiseToPayWizard = true
      },
      closePromiseToPayWizard(windowType = '') {
        this.promiseToPayWindowType = windowType
        this.modals.promiseToPayWizard = false
      },
      closePaymentWizard() {
        this.modals.paymentWizard = false
      },
    },
  }
</script>
