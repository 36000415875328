<template>
  <AppPanel
    v-if="isSummary"
    route-name="support"
    label="Support"
  >
    <AppPanel
      variant="alternate"
      class="mt-6 hidden lg:block"
    >
      <div class="justify-between gap-8 lg:flex">
        <div
          v-for="article in supportSummary"
          :key="article.title"
          class="w-full"
        >
          <div class="flex flex-col gap-2">
            <BzlIcon
              :customIconName="article.title"
              :src="article.iconName"
              style="font-size: 3rem"
              icon-color="#00A8EC"
            ></BzlIcon>
            <div class="font-bold">{{ article.title }}</div>
            <div class="space-y-4">
              <div
                v-for="item in article.articles"
                :key="item.title"
              >
                <a
                  :href="item.url"
                  target="_blank"
                  class="leading-tight underline hover:text-blue"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppPanel>
    <AppPanel
      variant="transparent"
      padding="none"
      class="block lg:hidden"
      ><div>Visit support for more assistance</div></AppPanel
    >
  </AppPanel>
  <AppPanel
    v-else
    label="Support Articles"
  >
    <div class="grid gap-4 lg:grid-cols-3">
      <div
        class="flex h-full flex-col items-start gap-2 rounded bg-gray-light p-6 shadow"
        v-for="article in supportArticles"
        :key="article.title"
      >
        <div class="flex items-center gap-4">
          <BzlIcon
            :customIconName="article.title"
            :src="article.iconName"
            style="font-size: 3rem"
            icon-color="#00A8EC"
          ></BzlIcon>
          <div class="font-bold">{{ article.title }}</div>
        </div>
        <ul class="list-disc space-y-2 pl-4">
          <li
            v-for="item in article.articles"
            :key="item.title"
          >
            <a
              :href="item.url"
              target="_blank"
              class="leading-tight hover:text-blue hover:underline"
              >{{ item.title }}</a
            >
          </li>
        </ul>
        <BzlButton
          :mFull="true"
          variant="default"
          size="large"
          class="mt-auto pt-2"
          :href="article.ctaLink"
          target="_blank"
        >
          All {{ article.title }} Articles
        </BzlButton>
      </div>
    </div>
  </AppPanel>
</template>

<script>
  import AccountIcon from './account-icon.svg'
  import InternetIcon from './internet-icon.svg'
  import ServicesIcon from './services-icon.svg'
  import VoiceIcon from './phone-icon.svg'
  import BillingIcon from './billing-icon.svg'
  import EmailIcon from './email-icon.svg'
  import TvIcon from './tv-icon.svg'
  export default {
    name: 'SupportCategories',
    props: {
      isSummary: {
        type: Boolean,
        default: false,
        required: false,
      },
      articlesByCategory: {
        type: Function,
        required: true,
      },
      articlesBySubCategory: {
        type: Function,
        required: true,
      },
      articlesByTitle: {
        type: Function,
        required: true,
      },
    },
    computed: {
      supportSummary() {
        return [
          {
            title: 'My Services',
            articles: this.articlesByCategory('account').slice(2, 6),
            iconName: AccountIcon,
          },
          {
            title: 'Internet',
            articles: this.articlesByCategory('internet').slice(2, 6),
            iconName: InternetIcon,
          },
          {
            title: 'TV',
            articles: this.articlesByCategory('tv').slice(2, 6),
            iconName: TvIcon,
          },
          {
            title: 'Voice',
            articles: this.articlesByCategory('voice').slice(1, 5),
            iconName: VoiceIcon,
          },
        ]
      },
      supportArticles() {
        return [
          {
            title: 'Billing',
            articles: this.articlesBySubCategory('billing').slice(1, 4),
            iconName: BillingIcon,
            ctaLink: this.articlesByTitle('Billing').url,
          },
          {
            title: 'Account',
            articles: [
              ...this.articlesBySubCategory('billing').slice(5, 6),
              ...this.articlesBySubCategory('seasonal-plan').slice(5, 6),
              ...this.articlesBySubCategory('email-account').slice(5, 6),
            ],
            iconName: AccountIcon,
            ctaLink: this.articlesByTitle('Account').url,
          },
          {
            title: 'Internet',
            articles: this.articlesByCategory('internet').slice(1, 4),
            iconName: InternetIcon,
            ctaLink: this.articlesByTitle('Internet').url,
          },
          {
            title: 'Email',
            articles: this.articlesBySubCategory('email-account').slice(1, 4),
            iconName: EmailIcon,
            ctaLink: this.articlesByTitle('Email Account').url,
          },
          {
            title: 'TV',
            articles: this.articlesByCategory('tv').slice(2, 5),
            iconName: TvIcon,
            ctaLink: this.articlesByTitle('TV').url,
          },
          {
            title: 'Voice',
            articles: this.articlesByCategory('voice').slice(2, 5),
            iconName: VoiceIcon,
            ctaLink: this.articlesByTitle('Voice').url,
          },
        ]
      },
    },
  }
</script>
