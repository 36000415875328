<template>
  <div>
    <AppPanel :label="label">
      <div
        v-if="quickActions"
        class="flex flex-col justify-between gap-4 lg:flex-row"
      >
        <div
          v-for="item in quickActionItems"
          :key="item.title"
          class="flex grow flex-col items-start gap-2 rounded bg-gray-light px-4 py-4 shadow lg:py-8"
        >
          <a
            :href="item.url"
            :target="item.target"
            @click="trackEvent(item.trigger)"
            class="flex w-full flex-row-reverse items-center justify-end gap-4 lg:flex-col-reverse lg:justify-center"
          >
            <div class="text-lg font-bold">{{ item.title }}</div>
            <BzlIcon
              :customIconName="item.title"
              :src="item.iconName"
              style="font-size: 2rem"
            ></BzlIcon>
          </a>
        </div>
      </div>

      <form
        v-if="search"
        target="_blank"
        action="https://breezeline.com/search"
        @submit="trackEvent('trigger_search_button_click')"
      >
        <input
          type="search"
          name="search"
          id="search"
          class="w-full rounded border-0 bg-gray-light bg-no-repeat py-4 pl-8 text-lg text-black shadow"
          placeholder="Search..."
        />
      </form>

      <div
        v-if="questions"
        class="flex flex-col justify-between gap-4 lg:flex-row"
      >
        <div
          v-for="item in moreQuestionsItems"
          :key="item.title"
          class="flex grow flex-col items-start gap-2 rounded bg-gray-light px-4 py-4 shadow lg:py-8"
        >
          <a
            v-if="!item.id"
            :href="item.url"
            :target="item.target"
            @click="trackEvent(item.trigger)"
            class="flex w-full flex-row-reverse items-center justify-end gap-4 lg:flex-col-reverse lg:justify-center"
          >
            <div class="text-lg font-bold">{{ item.title }}</div>
            <BzlIcon
              :customIconName="item.title"
              :src="item.iconName"
              style="font-size: 3rem"
            ></BzlIcon>
          </a>
          <div
            v-else-if="item.id === 'chat'"
            :id="item.id"
            @click="trackEvent(item.trigger)"
            class="flex w-full cursor-pointer flex-row-reverse items-center justify-end gap-4 lg:flex-col-reverse lg:justify-center"
          >
            <div class="text-lg font-bold">{{ item.title }}</div>
            <BzlIcon
              :customIconName="item.title"
              :src="item.iconName"
              style="font-size: 3rem"
            ></BzlIcon>
          </div>
        </div>
      </div>
    </AppPanel>
  </div>
</template>

<script>
  import AccountIcon from './account-icon.svg'
  import EmailIcon from './email-icon.svg'
  import BillingIcon from './billing-icon.svg'
  import LockIcon from './lock-icon.svg'
  import ChatIcon from './chat-icon.svg'
  import PhoneIcon from './phone-icon.svg'
  import SearchIcon from './search-icon.svg'
  export default {
    name: 'SupportCenter',
    props: {
      search: {
        type: Boolean,
        default: false,
        required: false,
      },
      questions: {
        type: Boolean,
        default: false,
        required: false,
      },
      quickActions: {
        type: Boolean,
        default: false,
        required: false,
      },
      label: {
        type: String,
        default: 'How can we help you?',
        required: false,
      },
    },
    data: () => ({
      quickActionItems: [
        {
          title: 'Support Center',
          iconName: AccountIcon,
          url: 'https://www.breezeline.com/support',
          target: '_blank',
          trigger: 'trigger_support_center_clicked',
        },
        {
          title: 'Reset Password',
          iconName: LockIcon,
          url: 'https://www.breezeline.com/interstitial-change-password',
          target: '_blank',
          trigger: 'trigger_reset_password_clicked',
        },
        {
          title: 'Pay My Bill',
          iconName: BillingIcon,
          url: '/billing',
          target: '_self',
          trigger: 'trigger_pay_my_bill_click',
        },
        {
          title: 'Check My Email',
          iconName: EmailIcon,
          url: 'https://www.breezeline.com/interstitial-my-email',
          target: '_blank',
          trigger: 'trigger_check_my_email_click',
        },
      ],
      moreQuestionsItems: [
        {
          title: 'Chat with us',
          iconName: ChatIcon,
          id: 'chat',
          trigger: 'trigger_chat_with_us_clicked',
        },
        {
          title: 'Call 888-536-9600',
          iconName: PhoneIcon,
          url: 'tel:8885369600',
          target: '_blank',
          trigger: 'trigger_call_us_clicked',
        },
        {
          title: 'Find Answers Online',
          iconName: SearchIcon,
          url: 'https://www.breezeline.com/support#search',
          target: '_blank',
          trigger: 'trigger_visit_support_center_clicked',
        },
      ],
    }),
    computed: {
      cardHasId() {
        console.log('Clicked!')
      },
    },
  }
</script>

<style scoped>
  input[type='search'] {
    background-position: right 1.5em top 50% !important;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.2939 12.5786H13.3905L13.0703 12.2699C14.191 10.9663 14.8656 9.27387 14.8656 7.43282C14.8656 3.32762 11.538 0 7.43282 0C3.32762 0 0 3.32762 0 7.43282C0 11.538 3.32762 14.8656 7.43282 14.8656C9.27387 14.8656 10.9663 14.191 12.2699 13.0703L12.5786 13.3905V14.2939L18.2962 20L20 18.2962L14.2939 12.5786V12.5786ZM7.43282 12.5786C4.58548 12.5786 2.28702 10.2802 2.28702 7.43282C2.28702 4.58548 4.58548 2.28702 7.43282 2.28702C10.2802 2.28702 12.5786 4.58548 12.5786 7.43282C12.5786 10.2802 10.2802 12.5786 7.43282 12.5786Z' fill='%23001E62'/%3E%3C/svg%3E%0A") !important;
  }
</style>

<!-- @click.prevent="" -->
