<template>
  <BzlModal
    :label="updateTitle"
    :open="open"
    :inner-class="`lg:w-full
                   ${paymentSteps.entered ? 'h-full' : null} md:h-auto
                   flex
                   flex-col
                   p-4`"
    @sl-hide="dismiss()"
  >
    <ViewPromiseToPay
      v-if="!paymentSteps.entered && windowType === 'review'"
      :payment="$store.getters['billing/getPromiseToPayPayment']"
      @modify-promise-to-pay="modifyPayment($event)"
      @cancel-promise-to-pay="$emit('show-cancel-promise-to-pay', $event)"
    ></ViewPromiseToPay>

    <PromiseToPayStep1
      v-if="!paymentSteps.entered && windowType !== 'cancel' && windowType !== 'review'"
      v-bind="$attrs"
      :payment-details="paymentDetails"
      :window-type="windowType"
      v-on="$listeners"
      @cancel="dismiss()"
      @proceed="reviewPayment($event)"
    ></PromiseToPayStep1>

    <PromiseToPayStep2
      v-if="paymentSteps.entered && !paymentSteps.sent && windowType !== 'cancel'"
      v-bind="paymentDetails"
      @go-back="editPayment($event)"
      @cancel="dismiss()"
      @approve="submitPromiseToPay($event)"
    ></PromiseToPayStep2>

    <PromiseToPayStep3
      v-if="paymentSteps.entered && paymentSteps.sent"
      :window-type="windowType"
      :response-status="responseStatus"
      @close="dismiss()"
    ></PromiseToPayStep3>

    <PromiseToPayCancel
      v-if="!paymentSteps.entered && !paymentSteps.sent && windowType === 'cancel'"
      @go-back="dismiss()"
      @approve="cancelPromiseToPay()"
    ></PromiseToPayCancel>
  </BzlModal>
</template>

<script>
  import PromiseToPayStep1 from './step1.vue'
  import PromiseToPayStep2 from './step2.vue'
  import PromiseToPayStep3 from './step3.vue'
  import PromiseToPayCancel from './cancel.vue'
  import ViewPromiseToPay from './view.vue'

  /*
        Steps:
            (1) enter payment details OR make one-time-payment OR add new payment method (last 2 option closes the wizard and shows the CSG widget)
            (2) review if payment details are correct and approve
            (3) submit payment and wait for response
    */

  export default {
    name: 'PromiseToPayWizard',
    components: {
      PromiseToPayStep1,
      PromiseToPayStep2,
      PromiseToPayStep3,
      PromiseToPayCancel,
      ViewPromiseToPay,
    },
    props: {
      windowType: {
        type: String,
        default: '',
      },
      open: {
        default: true,
        type: Boolean,
        required: false,
      },
    },
    data: () => ({
      paymentSteps: {
        entered: false,
        sent: false,
      },
      paymentDetails: {}, // populated by step1's submission
      responseStatus: false,
    }),
    mounted() {
      // this.trackEvent('trigger_make_a_payment_clicked')
    },
    computed: {
      updateTitle() {
        if (!this.paymentSteps.entered && this.windowType === 'review') {
          return 'Review Your Scheduled Payment'
        } else if (
          !this.paymentSteps.entered &&
          !this.paymentSteps.sent &&
          this.windowType === 'cancel'
        ) {
          return 'Cancel Promise to Pay'
        } else if (!this.paymentSteps.entered) {
          return 'Promise to Pay'
        } else if (
          this.paymentSteps.entered &&
          !this.paymentSteps.sent &&
          this.windowType !== 'cancel'
        ) {
          return `Promise to Pay`
        } else if (this.paymentSteps.entered && this.paymentSteps.sent) {
          return 'Confirmation'
        }
      },
    },
    methods: {
      dismiss() {
        this.trackEvent('P_PAY_NOW_CLOSED')
        this.$emit('dismiss')
      },
      reviewPayment(paymentDetails) {
        this.paymentDetails = paymentDetails
        this.paymentSteps.entered = true
      },
      modifyPayment(paymentDetails) {
        this.trackEvent('trigger_review_or_modify_payment_clicked')
        this.paymentDetails = paymentDetails
        this.paymentSteps.entered = false
        this.$emit('modify-promise-to-pay')
      },
      editPayment(paymentDetails) {
        this.trackEvent('trigger_re_enter_payment_info')
        this.paymentDetails = paymentDetails
        this.paymentSteps.entered = false
      },
      submitPromiseToPay(scheduleToPromise) {
        this.trackEvent('trigger_submit_payment_clicked')

        if (this.windowType === 'modify') {
          this.$emit('cancel-promise-to-pay', (status) => {
            if (status) {
              this.$emit('submit-promise-to-pay', this.paymentDetails, (status) => {
                this.responseStatus = status
                this.paymentSteps.entered = true
                this.paymentSteps.sent = true
              })
            } else {
              this.responseStatus = status
              this.paymentSteps.entered = true
              this.paymentSteps.sent = true
            }
          })
        } else {
          if (scheduleToPromise?.canConvert) {
            this.$emit(
              'convert-to-promise-to-pay',
              { ...this.paymentDetails, scheduleToPromise },
              (status) => {
                this.responseStatus = status
                this.paymentSteps.sent = true
              }
            )
          } else {
            this.$emit('submit-promise-to-pay', this.paymentDetails, (status) => {
              this.responseStatus = status
              this.paymentSteps.sent = true
            })
          }
        }
      },
      cancelPromiseToPay() {
        this.$emit('cancel-promise-to-pay', (status) => {
          this.responseStatus = status
          this.paymentSteps.entered = true
          this.paymentSteps.sent = true
        })
      },
    },
  }
</script>
