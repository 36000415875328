<template>
  <main>
    <AppPanel
      label="Account"
      variant="transparent"
      spacing="none"
      padding="none"
      alignment="center"
      class="lg:hidden"
    ></AppPanel>
    <AppPanel label="My Account">
      <AppSuspense :dependencies="['account']">
        <template #loading>
          <AppPanel variant="alternate">
            <p class="animate-pulse">Loading account details</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-error">Unable to load account details. Please try again later.</p>
          </AppPanel>
        </template>
        <template #ready>
          <AccountDetails
            v-bind="$store.state.account"
            :loginEmail="$store.state.login.email"
            :accountSignIn="true"
            @logout="$store.dispatch('logout')"
            @change-preferred-email="changePreferredEmail"
          ></AccountDetails>
        </template>
      </AppSuspense>

      <AppSuspense :dependencies="['customerPrograms']">
        <template #loading>
          <AppPanel variant="alternate">
            <p class="animate-pulse">Loading customer program details</p>
          </AppPanel>
        </template>
        <template #error>
          <AppPanel variant="alternate">
            <p class="text-error">Unable to load customer programs. Please try again later.</p>
          </AppPanel>
        </template>
        <template #ready>
          <CustomerPrograms></CustomerPrograms>
        </template>
      </AppSuspense>

      <AppPanel
        label="Manage Sign In Options"
        trigger="trigger_seasonal_plans_link_clicked"
        variant="alternate"
        :button="true"
        padding="none"
        externalLink="https://manage.my.breezeline.com/login-options"
        target="_blank"
      ></AppPanel>
      <AppPanel
        label="Seasonal Plans"
        trigger="trigger_seasonal_plans_link_clicked"
        variant="alternate"
        :button="true"
        padding="none"
        externalLink="https://www.breezeline.com/my-services/seasonal"
        target="_blank"
      ></AppPanel>
      <AppPanel
        label="Parental Controls"
        variant="alternate"
        :button="true"
        padding="none"
        externalLink="https://parental-control.my.breezeline.com/"
        target="_blank"
      ></AppPanel>
      <NeedHelp></NeedHelp>
    </AppPanel>
  </main>
</template>

<script>
  import AccountDetails from '../../components/AccountDetails/index.vue'
  import NeedHelp from '../../components/NeedHelp/index.vue'
  import LoginDetails from '../../components/LoginDetails/index.vue'
  import CustomerPrograms from '../../components/CustomerPrograms/index.vue'

  export default {
    name: 'AccountPage',
    components: {
      AccountDetails,
      NeedHelp,
      LoginDetails,
      CustomerPrograms,
    },
    data: () => ({
      updateEnrollAction: false,
      modals: {
        enrollLoyalty: false,
      },
    }),
    methods: {
      changePreferredEmail(newEmail, callback) {
        this.$store.dispatch('account/changePreferredEmail', newEmail).then(callback)
      },
    },
  }
</script>
