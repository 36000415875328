<template>
  <sl-menu
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    class="BzlMenu"
  >
    <slot></slot>
  </sl-menu>
</template>

<script>
  import '@shoelace-style/shoelace/dist/components/menu/menu.js'
  export default {
    name: 'BzlMenu',
    // props: {} // todo
  }
</script>
