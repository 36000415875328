<template>
  <BzlModal
    @sl-after-hide="$emit('dismiss')"
    label="Please confirm you want to opt out of Paperless Billing"
  >
    <div>
      <p>
        Accounts that are not enrolled in Paperless Billing are charged an additional $1 each month
      </p>
    </div>
    <hr class="mt-6 border-gray-light pt-4 lg:mt-8" />
    <div class="flex w-full flex-row justify-between">
      <BzlButton
        variant="text"
        @click="$emit('dismiss')"
      >
        Cancel
      </BzlButton>
      <BzlButton
        variant="primary"
        @click="confirm()"
      >
        Confirm
      </BzlButton>
    </div>
  </BzlModal>
</template>

<script>
  export default {
    name: 'DisableEcosave',
    props: {
      willLoseDiscount: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {},
    methods: {
      confirm() {
        this.trackEvent('ES_DISABLE_ECOSAVE_CLICKED')
        this.$emit('confirm', () => {
          this.$emit('dismiss')
        })
      },
    },
  }
</script>
