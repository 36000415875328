<template>
  <div class="flex h-screen flex-row items-center justify-center">
    <div class="w-1/2 space-y-4 rounded bg-white p-8 text-center shadow">
      <h1 class="text-xl font-bold text-error">
        <i class="fas fa-cogs"></i> My Account is currently under maintenance.
      </h1>
      <p>
        Online ordering as well as account management features on
        <a
          href="https://www.breezeline.com"
          class="text-navy underline"
        >
          breezeline.com
        </a>
        and in our My Breezeline mobile app are currently unavailable due to scheduled maintenance.
        We apologize for any inconvenience.
      </p>
      <p>
        Please
        <a
          class="text-navy underline"
          href="/"
        >
          try again
        </a>
        later.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MaintenancePage',
  }
</script>
