<template>
  <div>
    <AppPanel variant="alternate">
      <div>Customer Programs</div>
      <div class="flex flex-col justify-between gap-4 lg:flex-row">
        <div
          v-if="this.$store.state.account.customerInfo?.referralCode"
          class="flex w-full flex-col gap-4 border-b border-gray pb-4 lg:border-b-0 lg:border-r"
        >
          <p class="heading-text">Your Unique Referral Code</p>
          <p>
            Share this code with your friends and family and you could get a $50 statement credit!
          </p>
          <a
            @click="trackEvent('trigger_referral_code_learn_more_clicked')"
            class="learn-more"
            target="_blank"
            href="https://breezeline.com/referral"
            >Learn More</a
          >
          <div class="box">
            <p
              @click="copyToClipboard(), trackEvent('trigger_referral_code_copied')"
              class="code"
            >
              {{ this.$store.state.account.customerInfo?.referralCode }}
            </p>
            <p
              @click="copyToClipboard(), trackEvent('trigger_referral_code_copied')"
              class="code"
            >
              Click to copy
            </p>
          </div>
        </div>
        <div
          v-if="this.$store.state.customerPrograms.loyaltyInfo?.enrolled"
          class="flex w-full flex-col gap-4"
        >
          <p class="heading-text">Breezeline Rewards</p>
          <p>
            Welcome to Breezeline Rewards. Joined
            <span class="enroll-date">{{ rearrangeEnrollDate() }}</span>
          </p>
          <a
            @click="trackEvent('trigger_referral_code_learn_more_clicked')"
            class="learn-more"
            target="_blank"
            href="https://breezeline.com/breezeline-rewards"
            >Learn More</a
          >
          <div>
            <a
              @click.prevent=";(modals.enrollLoyalty = true), (updateEnrollAction = false)"
              class="revoke"
              target="_blank"
              href="https://breezeline.com/breezeline-rewards"
              >Leave Breezeline Rewards</a
            >
          </div>
        </div>
        <div
          v-else-if="
            this.$store.state.customerPrograms.loyaltyInfo?.availability?.available == false
          "
          class="flex w-full flex-col gap-4"
        >
          <p class="heading-text">Breezeline Rewards</p>
          <p>
            Unfortunately you are not eligible to join Breezeline Rewards at this time. In order to
            become eligible and join you will need to make a payment to bring your account current.
          </p>
          <a
            @click="trackEvent('trigger_referral_code_learn_more_clicked')"
            class="learn-more"
            target="_blank"
            href="https://breezeline.com/breezeline-rewards"
            >Learn More</a
          >
        </div>
        <div
          v-else
          class="flex w-full flex-col gap-4"
        >
          <p class="heading-text">Breezeline Rewards</p>
          <p>
            Join today to access a variety of rewards towards dining, wellness, events and much
            more!
          </p>
          <a
            @click="trackEvent('trigger_referral_code_learn_more_clicked')"
            class="learn-more"
            target="_blank"
            href="https://breezeline.com/breezeline-rewards"
            >Learn More</a
          >
          <div>
            <BzlSwitch
              @click.prevent=";(modals.enrollLoyalty = true), (updateEnrollAction = true)"
            ></BzlSwitch>
            <span class="heading-text">Join Breezeline Rewards</span>
          </div>
        </div>
      </div>
    </AppPanel>
    <BzlModal
      :open="modals.enrollLoyalty"
      label="Breezeline Rewards"
      @sl-after-hide="modals.enrollLoyalty = false"
    >
      <div class="space-y-4">
        <p
          v-if="updateEnrollAction"
          class="text-sm"
        >
          By joining Breezeline Rewards you agree to
          <a
            class="terms"
            target="_blank"
            href="https://breezeline.com/breezeline-rewards"
            >terms & conditions</a
          >
          of the program.
        </p>
        <p
          v-else
          class="text-sm"
        >
          Please confirm you would like to leave Breezeline Rewards. Once you leave, you will no
          longer receive the benefits of the program.
        </p>
      </div>
      <hr class="mt-4 pt-4 lg:mt-8" />
      <div class="flex w-full flex-row justify-between">
        <BzlButton
          variant="text"
          @click="modals.enrollLoyalty = false"
        >
          Cancel
        </BzlButton>
        <BzlButton
          v-if="updateEnrollAction"
          variant="primary"
          @click="trackEvent('trigger_enroll_breezeline_loyalty'), updateEnrollLoyalty('ENROLL')"
        >
          Join
        </BzlButton>
        <BzlButton
          v-else
          variant="primary"
          @click="
            trackEvent('trigger_unenroll_breezeline_loyalty'), updateEnrollLoyalty('UNENROLL')
          "
        >
          Leave
        </BzlButton>
      </div>
    </BzlModal>
  </div>
</template>

<script>
  export default {
    name: 'CustomerPrograms',
    data: () => ({
      updateEnrollAction: false,
      modals: {
        enrollLoyalty: false,
      },
    }),
    computed: {},
    methods: {
      copyToClipboard() {
        const referralCode = this.$store.state.account.customerInfo?.referralCode
        navigator.clipboard.writeText(referralCode)
      },
      updateEnrollLoyalty(action, callback) {
        this.modals.enrollLoyalty = false
        this.$store.dispatch('customerPrograms/updateLoyaltyStatus', action).then(callback)
      },
      rearrangeEnrollDate() {
        const date = this.$store.state.customerPrograms.loyaltyInfo?.enrollmentDate
        return `${date.slice(5, 8)}${date.slice(8)}-${date.slice(0, 4)}`
      },
    },
  }
</script>

<style scoped>
  .learn-more {
    text-decoration: underline;
    color: rgb(0 87 184 / var(--tw-text-opacity));
  }
  .revoke {
    color: rgb(0 87 184 / var(--tw-text-opacity));
  }
  .box {
    display: flex;
    gap: 20px;
    padding-top: 0;
  }
  .code {
    font-weight: bolder;
    cursor: pointer;
    text-decoration: underline;
  }
  .terms {
    text-decoration: underline;
  }
  .heading-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 23.31px;
  }
  .enroll-date {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
  }
</style>
